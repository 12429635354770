import React, { MouseEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { useGTMDataLayer } from '../../../../hooks/GTM/useGTMDataLayer';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import { DocumentTypeLabel } from '../../../../../../types/testType';
import { InternalLinkInDialog } from './InternalLinkInDialog';

interface InternalLinkProps {
  value?: {
    referenceComponent: { slug: string; type: DocumentTypeLabel; name: string };
    anchorId?: string;
    queryParams: { value: string; key: string }[];
    openInDialog: boolean;
  };
  children: ReactNode | ReactNode[] | string | string[];
}

export const InternalLinkMarks = ({
  value: { referenceComponent, anchorId, queryParams, openInDialog },
  children,
}: InternalLinkProps) => {
  const history = useHistory();
  const location = useLocation();
  const dataLayer = useGTMDataLayer();
  const hostname = (typeof window !== 'undefined' && window.location.hostname) || undefined;
  const href = useDocumentUrlWithNode(referenceComponent, anchorId, queryParams) || '';

  if (!referenceComponent?.slug) return children;

  if (openInDialog) {
    const trackingFunction: MouseEventHandler<HTMLAnchorElement> = (e) => {
      e.preventDefault();
      dataLayer?.push({
        event: 'link_click',
        link_text: Array.isArray(children) ? children.join(' ') : children.toString(),
        link_location: location.pathname,
        link_url: `${hostname}${href}`,
        link_hostname: hostname,
      });
    };

    return (
      <InternalLinkInDialog href={href} trackingFunction={trackingFunction}>
        {children}
      </InternalLinkInDialog>
    );
  }

  if (referenceComponent.type === 'nafMembershipBenefit' && referenceComponent.slug) {
    const handleBenefitCTATracking: MouseEventHandler<HTMLAnchorElement> = (e) => {
      e.preventDefault();
      dataLayer?.push({
        event: 'link_click',
        link_text: referenceComponent.name,
        link_location: location.pathname,
        link_url: href,
        link_hostname: hostname,
      });
      history.push(href);
    };
    return (
      <Link to={href} onClick={handleBenefitCTATracking}>
        {children}
      </Link>
    );
  }

  const handleOtherDocumentsCTATracking: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    dataLayer?.push({
      event: 'link_click',
      link_text: Array.isArray(children) ? children.join(' ') : children.toString(),
      link_location: location.pathname,
      link_url: `${hostname}${href}`,
      link_hostname: hostname,
    });
    history.push(href);
  };

  return (
    <Link to={href} onClick={handleOtherDocumentsCTATracking}>
      {children}
    </Link>
  );
};
