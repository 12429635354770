import React, { ReactNode, useRef, useContext } from 'react';
import loadable from '@loadable/component';
import { NafThemeType, themeJustSuper, themeLight, themeVisualBreak } from '@naf/theme';
import { useSpring, animated, config } from '@react-spring/web';
import { CardSize } from '@naf/cards';
import { ThemeContextValue } from '../../lib/ThemeContext';
import { SectionBlockType } from '../../../../types/CategoryAppType';
import { ThemeVariants } from '../../../../types/themes';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { ReferencedComponentBlock } from '../block-content/components/types/components/ReferencedComponentSerializer';
import { MainEntries } from '../../pages/FrontPage/components/MainEntries';
import { Shortcuts } from '../../pages/FrontPage/components/Shortcuts';
import { MembershipBenefitHighlightListBlock } from './blocks/MembershipBenefitHighlightListBlock';

const Timeline = loadable(() => import('./blocks/Timeline/Timeline'));
const ImageTitleAndLinkList = loadable(() => import('./blocks/ImageTitleAndLinkList'));
const SecondaryEntriesBlock = loadable(() => import('./blocks/SecondaryEntriesBlock'));
const StepByStepList = loadable(() => import('./blocks/StepByStepList'));

const EmbeddedArticleBlock = loadable(() => import('./blocks/EmbeddedArticle/EmbeddedArticleBlock'), {
  resolveComponent: (components) => components.EmbeddedArticleBlock,
});

const TravelSuggestions = loadable(() => import('./blocks/TravelSuggestions/TravelSuggestions'), {
  resolveComponent: (components) => components.TravelSuggestions,
});

const TabBlock = loadable(() => import('./blocks/TabBlock'), {
  resolveComponent: (components) => components.TabBlock,
});

const MembershipBenefitListBlock = loadable(() => import('./blocks/MembershipBenefitListBlock'), {
  resolveComponent: (components) => components.MembershipBenefitListBlock,
});

const SingleNavigationCardBlock = loadable(() => import('./ColumnContent/SingleNavigationCardBlock'), {
  resolveComponent: (components) => components.SingleNavigationCardBlock,
});

const VerticalArticleBlock = loadable(() => import('./ColumnContent/VerticalArticleBlock'), {
  resolveComponent: (components) => components.VerticalArticleBlock,
});

const ColumnBlock = loadable(() => import('./ColumnBlock'), {
  resolveComponent: (components) => components.ColumnBlock,
});

const FeaturedVehicleTestResult = loadable(() => import('./FeaturedVehicleTestResult'), {
  resolveComponent: (components) => components.FeaturedVehicleTestResult,
});

const Results = loadable(() => import('./Testshowcase/Results'), {
  resolveComponent: (components) => components.Results,
});

const FeaturedBenefitBlock = loadable(() => import('./blocks/FeaturedBenefitBlocks/FeaturedBenefitBlock'), {
  resolveComponent: (components) => components.FeaturedBenefitBlock,
});

const IframeBlock = loadable(() => import('./blocks/IframeBlock'), {
  resolveComponent: (components) => components.IframeBlock,
});

const InputWithImageLinkBlock = loadable(() => import('./blocks/InputWithImageLinkBlock/InputWithImageLinkBlock'), {
  resolveComponent: (components) => components.InputWithImageLinkBlock,
});

const ColorSection = loadable(() => import('../../pages/TopicPageApp/ColorSection'), {
  resolveComponent: (components) => components.ColorSection,
});

const PortraitsWithTextBlock = loadable(() => import('./blocks/PortraitsWithTextBlock'), {
  resolveComponent: (components) => components.PortraitsWithTextBlock,
});

const MembershipBenefitsBlock = loadable(() => import('./blocks/MembershipBenefits'), {
  resolveComponent: (components) => components.MembershipBenefitsBlock,
});

const CampaignBlock = loadable(() => import('./blocks/Campaign'), {
  resolveComponent: (components) => components.CampaignBlock,
});

const TextAndImageBlock = loadable(() => import('./blocks/TextAndImageBlock'), {
  resolveComponent: (components) => components.TextAndImageBlock,
});

const NewsBlock = loadable(() => import('./blocks/News/NewsBlock'), {
  resolveComponent: (components) => components.NewsBlock,
});

const LatestArticlesBlock = loadable(() => import('./blocks/LatestArticleBlock'), {
  resolveComponent: (components) => components.LatestArticlesBlock,
});

const HeroBlock = loadable(() => import('./blocks/HeroBlock'), {
  resolveComponent: (components) => components.HeroBlock,
});

const CategoryListWithScrollBlock = loadable(() => import('./blocks/CategoryListWithScroll'), {
  resolveComponent: (components) => components.CategoryListWithScrollBlock,
});

const ThreeColumnBlock = loadable(() => import('./blocks/ThreeColumnBlock'), {
  resolveComponent: (components) => components.ThreeColumnBlock,
});

const AccordionBlock = loadable(() => import('./blocks/AccordionBlock'), {
  resolveComponent: (components) => components.AccordionBlock,
});

const AccordionListBlock = loadable(() => import('./blocks/AccordionListBlock'), {
  resolveComponent: (components) => components.AccordionListBlock,
});

const NewsfeedBlock = loadable(() => import('./blocks/NewsfeedBlock'), {
  resolveComponent: (components) => components.NewsfeedBlock,
});

const ThemeHeroBlock = loadable(() => import('./blocks/ThemeHeroBlock/ThemeHeroBlock'), {
  resolveComponent: (components) => components.ThemeHeroBlock,
});

const CountdownBlock = loadable(() => import('./blocks/CountdownBlock'), {
  resolveComponent: (components) => components.CountdownBlock,
});

const QnaFeedBlock = loadable(() => import('./blocks/QnaFeedBlock'), {
  resolveComponent: (components) => components.QnaFeedBlock,
});

const ScrollableTextBlocksWithBackgroundImages = loadable(
  () => import('./blocks/Parallax/ScrollableTextBlocksWithBackgroundImages'),
  {
    resolveComponent: (components) => components.ScrollableTextBlocksWithBackgroundImages,
  },
);

const ShowcaseBlock = loadable(() => import('./Testshowcase/ShowcaseBlock'), {
  resolveComponent: (components) => components.ShowcaseBlock,
});

const LivestreamBlock = loadable(() => import('./blocks/LivestreamBlock'), {
  resolveComponent: (components) => components.LivestreamBlock,
});

const RouteCharts = loadable(() => import('./blocks/Charts/RouteCharts'), {
  resolveComponent: (components) => components.RouteCharts,
});

const GoogleMapsWrapper = loadable(() => import('./blocks/Charts/GoogleMapsWrapper'), {
  resolveComponent: (components) => components.GoogleMapsWrapper,
});

const TextBlock = loadable(() => import('./blocks/TextBlock'), {
  resolveComponent: (components) => components.TextBlock,
});

const KeyStatisticsBlock = loadable(() => import('./blocks/KeyStatistics/KeyStatisticsBlock'), {
  resolveComponent: (components) => components.KeyStatisticsBlock,
});

const MultiRowArticleBlock = loadable(() => import('./blocks/MultiRowArticleBlock'), {
  resolveComponent: (components) => components.MultiRowArticleBlock,
});

const NavigationLinksBlock = loadable(() => import('./blocks/NavigationLinksBlock'), {
  resolveComponent: (components) => components.NavigationLinksBlock,
});

const ImageLinksBlock = loadable(() => import('./blocks/ImageLinks/ImageLinksBlock'), {
  resolveComponent: (components) => components.ImageLinksBlock,
});

const DocumentSnapshots = loadable(() => import('./blocks/DocumentSnapshots'), {
  resolveComponent: (components) => components.DocumentSnapshots,
});

const BilguideSearchBlock = loadable(() => import('./blocks/BilguideSearchBlock'), {
  resolveComponent: (components) => components.BilguideSearchBlock,
});

const FeaturedModelBlock = loadable(() => import('./FeaturedModelBlock/FeaturedModelBlock'), {
  resolveComponent: (components) => components.FeaturedModelBlock,
});

const TwoArticleBlock = loadable(() => import('./blocks/TwoArticleBlock'), {
  resolveComponent: (components) => components.TwoArticleBlock,
});

const TrialMembershipNavigationCardContainerBlock = loadable(
  () => import('./blocks/TrialMembership/welcomeCard/TrialMembershipNavigationCardContainerBlock'),
  {
    resolveComponent: (components) => components.TrialMembershipNavigationCardContainerBlock,
  },
);

const BodyCtaAndBodyBannerBlock = loadable(() => import('./blocks/TrialMembership/banner/BodyCtaAndBodyBannerBlock'), {
  resolveComponent: (components) => components.BodyCtaAndBodyBannerBlock,
});

const QRCodeBlock = loadable(() => import('./blocks/QRCode'), {
  resolveComponent: (components) => components.QRCodeBlock,
});

const VippsLoginBlock = loadable(() => import('./blocks/VippsLogin'), {
  resolveComponent: (components) => components.VippsLoginBlock,
});

const ProductsLoggedInActivationFormBlock = loadable(() => import('./blocks/ProductsLoggedInActivationForm'), {
  resolveComponent: (components) => components.ProductsLoggedInActivationForm,
});

const TrialMembershipWelcomeCardBlock = loadable(
  () => import('./blocks/TrialMembership/welcomeCard/TrialMembershipWelcomeCardBlock'),
  {
    resolveComponent: (components) => components.TrialMembershipWelcomeCardBlock,
  },
);

const CardCarouselBlock = loadable(() => import('./blocks/TrialMembership/cardCarousel/CardCarouselBlock'), {
  resolveComponent: (components) => components.CardCarouselBlock,
});

const CompareMembershipBlock = loadable(() => import('./blocks/CompareMembershipBlock'), {
  resolveComponent: (components) => components.CompareMembershipBlock,
});

const ConsumerGuidesBlock = loadable(() => import('./blocks/ConsumerGuideBlock/ConsumerGuideBlock'), {
  resolveComponent: (components) => components.ConsumerGuideBlock,
});

const ModularButtonBlock = loadable(() => import('./blocks/ModularButtonBlock'), {
  resolveComponent: (components) => components.ModularButtonBlock,
});

const ConsumerTestProductList = loadable(
  () => import('../block-content/components/types/components/ConsumerTestProductList/ConsumerTestProductList'),
  {
    resolveComponent: (components) => components.ConsumerTestProductList,
  },
);

interface Props {
  content?: SectionBlockType[];
  activateContextThemeSwitch?: boolean;
}

const mapBlockTheme = (block?: SectionBlockType, theme?: NafThemeType) => {
  // Assume this functionallity is only used for timeline component with parallax effect
  if (block?.type === 'timeline') {
    switch (block.colorScheme) {
      case ThemeVariants.Standard:
        return themeLight;
      case ThemeVariants.VisualBreak:
        return themeVisualBreak;
      case ThemeVariants.JustSuper:
        return themeJustSuper;
      default:
        return themeLight;
    }
  }
  return theme || themeLight;
};

const Section = ({
  block,
  children,
  currentTheme,
}: {
  block: SectionBlockType;
  children: ReactNode;
  currentTheme?: NafThemeType;
}) => <ColorSection theme={mapBlockTheme(block, currentTheme)}>{children}</ColorSection>;

const Blocks = ({ block }: { block: SectionBlockType }) => {
  switch (block.type) {
    case 'news':
      return <NewsBlock data={block.data} />;
    case 'membershipBenefits':
      return <MembershipBenefitsBlock benefits={block.data} total={block.total} title={block.title} />;
    case 'campaignBlock':
      return <CampaignBlock data={block.data} />;
    case 'textAndImage':
      return <TextAndImageBlock block={block} />;
    case 'latestArticles':
      return <LatestArticlesBlock block={block} />;
    case 'hero':
      return <HeroBlock block={block} />;
    case 'categoryScrollList':
      return <CategoryListWithScrollBlock block={block} />;
    case 'threeColumn':
      return <ThreeColumnBlock block={block} />;
    case 'accordionBlock':
      return <AccordionBlock block={block} />;
    case 'accordionListBlock':
      return <AccordionListBlock block={block} />;
    case 'timeline':
      return <Timeline block={block} />;
    case 'countdownBlock':
      return <CountdownBlock block={block} />;
    case 'newsfeedBlock':
      return <NewsfeedBlock block={block.data} />;
    case 'questionsAndAnswersBlock':
      return <QnaFeedBlock block={block} />;
    case 'heroWithTitleIngressSmallImageAndCta':
      return <ThemeHeroBlock block={block} />;
    case 'scrollableTextBlocksWithBackgroundImages':
      return <ScrollableTextBlocksWithBackgroundImages key={block.key} block={block} />;
    case 'testshowcase':
      return <ShowcaseBlock block={block} />;
    case 'livestreamBlock':
      return <LivestreamBlock key={block.key} block={block} />;
    case 'textBlock':
      return <TextBlock block={block} />;
    case 'navigationLinks':
      return <NavigationLinksBlock block={block} />;
    case 'keyStatistics':
      return <KeyStatisticsBlock block={block} />;
    case 'routeBlock':
      return (
        <GoogleMapsWrapper>
          <RouteCharts block={block} />
        </GoogleMapsWrapper>
      );
    case 'multiRowArticleBlock':
      return <MultiRowArticleBlock block={block} />;
    case 'imageLinks':
      return <ImageLinksBlock block={block} />;
    case 'portraitsWithText':
      return <PortraitsWithTextBlock block={block} />;
    case 'inputWithImageLinkBlock':
      return <InputWithImageLinkBlock block={block} />;
    case 'iframeBlock':
      return <IframeBlock block={block} />;
    case 'featuredBenefitCampaign':
      return <FeaturedBenefitBlock block={block} />;
    case 'testResultBlock':
      return <Results block={block} />;
    case 'featuredVehicleTestResult':
      return <FeaturedVehicleTestResult block={block} />;
    case 'columnBlock':
      return <ColumnBlock block={block} />;
    case 'simpleArticle':
      return <VerticalArticleBlock block={block} />;
    case 'entry':
      return <SingleNavigationCardBlock size={CardSize.Large} block={block} />;
    case 'imageTitleAndLinkList':
      return <ImageTitleAndLinkList block={block} />;
    case 'stepByStepList':
      return <StepByStepList block={block} />;
    case 'membershipBenefitListBlock':
      return <MembershipBenefitListBlock />;
    case 'documentSnapshots':
      return <DocumentSnapshots block={block} />;
    case 'tabsSection':
      return <TabBlock block={block} />;
    case 'tabsSectionMembershipBenefit':
      return <TabBlock block={block} />;
    case 'secondaryEntriesBlock':
      return <SecondaryEntriesBlock block={block} />;
    case 'bilguideSearchBlock':
      return <BilguideSearchBlock block={block} />;
    case 'featuredModelBlock':
      return <FeaturedModelBlock block={block} />;
    case 'travelSuggestionsBlock':
      return <TravelSuggestions />;
    case 'nafnoArticleEmbedded':
      return <EmbeddedArticleBlock block={block} />;
    case 'referencedComponent':
      return <ReferencedComponentBlock value={block} />;
    case 'twoArticleBlock':
      return <TwoArticleBlock node={block} />;
    case 'trialMembershipNavigationCardContainerBlock':
      return <TrialMembershipNavigationCardContainerBlock block={block} />;
    case 'bodyCtaAndBodyBannerBlock':
      return <BodyCtaAndBodyBannerBlock block={block} />;
    case 'cardCarouselBlock':
      return <CardCarouselBlock block={block} />;
    case 'mainEntries':
      return <MainEntries node={block} />;
    case 'shortcuts':
      return <Shortcuts node={block} />;
    case 'qrCodeReference':
      return <QRCodeBlock node={block} />;
    case 'vippsLogin':
      return <VippsLoginBlock value={block} />;
    case 'trialMembershipWelcomeCardBlock':
      return <TrialMembershipWelcomeCardBlock queryParam={block.queryParam} />;
    case 'productsLoggedInActivationForm':
      return <ProductsLoggedInActivationFormBlock node={block} />;
    case 'compareMembershipBlock':
      return <CompareMembershipBlock block={block} />;
    case 'consumerGuideBlock':
      return <ConsumerGuidesBlock />;
    case 'membershipBenefitHighlights':
      return <MembershipBenefitHighlightListBlock block={block} />;
    case 'modularButtonBlock':
      return <ModularButtonBlock block={block} />;
    case 'consumerTestProductList':
      return <ConsumerTestProductList value={block} />;
    default:
      return null;
  }
};

export const SectionBlocks = ({ content, activateContextThemeSwitch }: Props) => {
  const theme = useContext(ThemeContextValue);
  if (content) {
    return (
      <>
        {content.map((block) =>
          activateContextThemeSwitch ? (
            <Section block={block} key={block.key} currentTheme={theme}>
              <Blocks block={block} />
            </Section>
          ) : (
            <Blocks block={block} key={block.key} />
          ),
        )}
      </>
    );
  }
  return null;
};

export const VerticallyFadingSectionBlocks = ({ content, activateContextThemeSwitch }: Props) => {
  const theme = useContext(ThemeContextValue);
  if (content) {
    return (
      <>
        {content.map((block) =>
          activateContextThemeSwitch ? (
            <Section block={block} key={block.key} currentTheme={theme}>
              <VerticallyFadingBlock block={block} />
            </Section>
          ) : (
            <VerticallyFadingBlock key={block.key} block={block} />
          ),
        )}
      </>
    );
  }
  return null;
};

const VerticallyFadingBlock = ({ block }: { block: SectionBlockType }) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const dataRef = useIntersectionObserver(triggerRef, { freezeOnceVisible: true });

  const [props] = useSpring(
    () => ({
      config: config.gentle,
      from: { opacity: 0, y: 40 },
      to: {
        opacity: dataRef?.isIntersecting ? 1 : 0,
        y: dataRef?.isIntersecting ? 0 : 40,
      },
    }),
    [dataRef],
  );

  return (
    <animated.div ref={triggerRef} style={props}>
      <Blocks block={block} />
    </animated.div>
  );
};
