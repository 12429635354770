import React from 'react';
import styled from 'styled-components';
import { spacing } from '@naf/theme';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import { ProsAndCons as ProsAndConsComponent } from '../../../../bilguide/ProsAndCons';
import { ProsAndCons } from '../../../../../../../types/carModelType';

interface ProsAndConsSerializerProps {
  value: ProsAndCons;
}

export const ProsAndConsBlock: React.FC<ProsAndConsSerializerProps> = ({ value }) => {
  if (!value) return null;

  const { pros, cons } = value;

  return (
    <StyledProsAndConsBlock>
      <ProsAndConsComponent pros={pros} cons={cons} />
    </StyledProsAndConsBlock>
  );
};

export const ProsAndConsSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: ProsAndConsSerializerProps) => (
      <ArticleBlockContentGridCol className="pros-cons">
        <ProsAndConsBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }

  return ({ value }: ProsAndConsSerializerProps) => <ProsAndConsBlock value={value} />;
};

const StyledProsAndConsBlock = styled.div`
  margin: ${spacing.space32} 0;
`;
