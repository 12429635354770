import React from 'react';
import { InternalLinkType } from '../../../../../../../../types/internalLinkType';
import { ExternalUrl } from './ExternalUrl';
import { InternalLink } from './InternalLink';
import { ArticleBlockContentGridCol } from '../ArticleBlockContentGridCol';

export interface ExternalGlobalCTAType {
  _key: string;
  _type: 'ctaGlobalBlock';
  data: {
    blank: boolean;
    buttonText: string;
    internalLink: null;
    type: 'ExternalUrl';
    url: string;
  };
}

export interface InternalGlobalCTAType {
  _key: string;
  _type: 'ctaGlobalBlock';
  data: {
    blank: boolean;
    buttonText: string;
    internalLink: InternalLinkType;
    type: 'InternalLink';
    url: null;
    queryParams?: { value: string; key: string }[];
  };
}

interface Props {
  value: ExternalGlobalCTAType | InternalGlobalCTAType;
}
export const CtaGlobalBlock = ({
  value: {
    data,
    data: { type },
  },
}: Props) => {
  if (type === 'InternalLink') {
    return <InternalLink data={data as InternalGlobalCTAType['data']} />;
  }
  if (type === 'ExternalUrl') {
    return <ExternalUrl data={data as ExternalGlobalCTAType['data']} />;
  }
  return null;
};

export const CtaGlobalBlockSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: Props) => (
      <ArticleBlockContentGridCol>
        <CtaGlobalBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: Props) => <CtaGlobalBlock value={value} />;
};
