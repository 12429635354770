import React, { useEffect, useState } from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { Text, TextVariant } from '@naf/text';
import { ImageCarousel } from '@naf/image-carousel';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { TrunkDimensions, TrunkTest } from '../../../../types/carModelType';
import BlockContent from '../../components/block-content/BlockContent';
import { useCloudinary } from '../../hooks/Cloudinary/useCloudinary';
import { useDevicePixelRatio } from '../../hooks/useDevicePixelRatio';
import { CloudinaryImageType } from '../../../../types/blockTypes';
import useSelector from '../../redux/typedHooks';
import { TrunkVehicleVariantRadio } from './VehicleVariantRadio';
import { DataTable } from './DataTable';
import { BlockContentWrapper } from './BlockContentWrapper';

function transformCloudinaryImages(images: CloudinaryImageType[], cld: Cloudinary, devicePixelRatio: number) {
  return images
    .map((image) => {
      const id = image.public_id || image.publicId;
      if (!id) return null;

      const src = cld
        .image(id)
        .resize(
          fill()
            .width(Math.round(1120 / devicePixelRatio))
            .gravity(autoGravity()),
        )
        .delivery(dpr(devicePixelRatio))
        .quality('auto:best')
        .format('auto')
        .toURL();

      return {
        src,
        altText: image.altText || image.source_altText || image.alt,
        description: image.caption || image.source_caption,
      };
    })
    .filter((image) => image !== null);
}

type TrunkTestsProps = {
  trunkTests: TrunkTest[];
  slug: string;
};

const TrunkTests = ({ trunkTests, slug }: TrunkTestsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  if (trunkTests.length === 0) {
    return null;
  }

  return (
    <TestWrapper id="bagasjeromstester">
      <TitleText variant={TextVariant.Header1} tag="h2">
        Test av bagasjerom
      </TitleText>
      {trunkTests.length > 1 ? (
        <TrunkVehicleVariantRadio name="trunk" tests={trunkTests} selected={activeTab} setSelected={setActiveTab} />
      ) : null}
      <TrunkTestContent slug={slug} trunkTest={trunkTests[activeTab]} />
    </TestWrapper>
  );
};

type TrunkTestContentProps = {
  trunkTest: TrunkTest;
  slug: string;
};

const TrunkTestContent = ({ trunkTest, slug }: TrunkTestContentProps) => {
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();
  const [cldImages, setCldImages] = useState(() =>
    transformCloudinaryImages(trunkTest.cloudinaryImages, cld, devicePixelRatio),
  );

  useEffect(
    () => setCldImages(transformCloudinaryImages(trunkTest.cloudinaryImages, cld, devicePixelRatio)),
    [trunkTest.cloudinaryImages, cld, devicePixelRatio],
  );

  return (
    <TestWrapper>
      <BlockContentWrapper>
        <BlockContent value={trunkTest.trunkTestSummary} />
      </BlockContentWrapper>
      <CarouselWrapper>
        <Subheader variant={TextVariant.Header2}>Fysiske mål på bilder</Subheader>
        <ImageCarousel images={cldImages} />
      </CarouselWrapper>
      <TestDataTable trunk={trunkTest.trunk} hasFrunk={trunkTest.hasFrunk} frunk={trunkTest.frunk} />
      {trunkTest.testedVariant && (
        <VariantDataTable slug={slug} trunk={trunkTest.trunk} variantId={trunkTest.testedVariant.id} />
      )}
    </TestWrapper>
  );
};

type TestDataTableProps = Pick<TrunkTest, 'trunk' | 'hasFrunk' | 'frunk'>;

const TestDataTable = ({ trunk, hasFrunk, frunk }: TestDataTableProps) => (
  <div>
    <DataTable
      tableTitle="Fysiske mål"
      hideDisclaimer
      entries={[
        { label: 'Gulv bredde', value: trunk.width, unit: 'cm' },
        { label: 'Gulv lengde', value: trunk.length, unit: 'cm' },
        { label: 'Høyde opp til setekant', value: trunk.height, unit: 'cm' },
        { label: 'Åpning bredde', value: trunk.openingWidth, unit: 'cm' },
        { label: 'Åpning høyde', value: trunk.openingHeight, unit: 'cm' },
        ...(hasFrunk
          ? [
              { label: 'Frunk bredde', value: frunk.width, unit: 'cm' },
              { label: 'Frunk høyde', value: frunk.height, unit: 'cm' },
              { label: 'Frunk lengde', value: frunk.length, unit: 'cm' },
            ]
          : [null]),
      ]}
    />
  </div>
);

type VariantDataTableProps = { variantId: string; slug: string; trunk: TrunkDimensions };

const VariantDataTable = ({ variantId, slug, trunk }: VariantDataTableProps) => {
  const variant = useSelector((state) =>
    state.cars.mappedData[slug].data.vehicleVariants.find(({ id }) => id === variantId),
  );

  if (!variant) {
    return null;
  }

  return (
    <div>
      <DataTable
        tableTitle="Om testbilen"
        entries={[
          { label: 'Chassis', value: variant.chassis },
          { label: 'Bagasjerom bak', value: trunk?.volume || variant.vehicleDimensions.cargospace_l, unit: 'liter' },
          { label: 'Nyttelast', value: variant.vehicleDimensions.maxPayloadkg, unit: 'kg' },
          { label: 'Taklast', value: variant.vehicleDimensions.maxRoofPayload_kg, unit: 'kg' },
          { label: 'Egenvekt uten fører', value: variant.vehicleDimensions.weight_kg, unit: 'kg' },
          { label: 'Tillatt totalvekt', value: variant.vehicleDimensions.allowedTotalWeight_kg, unit: 'kg' },
          { label: 'Hengerfeste', value: variant.trailerDetails.trailerHitch },
          variant.trailerDetails.trailerMaximumWeight_kg
            ? { label: 'Tilhengervekt', value: variant.trailerDetails.trailerMaximumWeight_kg, unit: 'kg' }
            : null,
        ]}
      />
    </div>
  );
};

const TestWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: ${spacing.space48};
  scroll-margin: 100px;
`;

const TitleText = styled(Text)`
  margin: 0;
`;

const Subheader = styled(Text)`
  margin: 0;
`;

const CarouselWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: ${spacing.space16};

  --aside: clamp(240px, 25vw, 400px);
  @media (max-width: ${breakpoints.l}) {
    --aside: 0;
  }

  --content-plus-whitespace: calc(100vw - var(--aside));
  --max-width-with-whitespace: min(calc(936px + 2 * 96px), var(--content-plus-whitespace));
  /* Margins will always be a negative number */
  --total-margins: calc((736px - var(--max-width-with-whitespace)) / 2);

  width: calc(736px - var(--total-margins));
  margin-bottom: 25px; /* To match the floating dots below the carousel */
  margin-left: max(var(--total-margins) / 2, -96px);
  margin-right: max(var(--total-margins) / 2, -96px);

  @media (max-width: ${breakpoints.m}) {
    margin-left: -${spacing.space24};
    margin-right: -${spacing.space24};
    width: calc(100% + ${spacing.space48});

    ${Subheader} {
      margin-left: ${spacing.space24};
    }
  }
`;

export { TrunkTests };
