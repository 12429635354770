// InternalLinkComponent.tsx
import React from 'react';
import { MembershipBenefitHighlightLinkType } from '../../../../types/MembershipBenefitHighlightLinkType';
import { StyledButtonLink } from './Styles';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';

interface InternalLinkComponentProps {
  link: MembershipBenefitHighlightLinkType;
}

export const MembershipBenefitHighlightLink = ({ link }: InternalLinkComponentProps) => {
  const { title, data } = link;
  const { queryParams } = data || {};
  const url = useDocumentUrlWithNode(data);

  const queryString = queryParams?.length
    ? `?${queryParams.map((param) => `${param.key}=${encodeURIComponent(param.value)}`).join('&')}`
    : '';

  const href = `${url}${queryString}`;

  return <StyledButtonLink to={href}>{title}</StyledButtonLink>;
};
