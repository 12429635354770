import React from 'react';
import { Button, ButtonSize, ButtonVariant } from '@naf/button';
import { useTrackCTA } from '../../utils/Tracking/CtaClick/TrackCtaClick';

interface CtaButtonProps {
  text: string;
  variant: ButtonVariant;
  size?: ButtonSize;
  onClickFn?: () => void;
  submit?: boolean;
  isLoading?: boolean;
}

const CtaButton = ({ text, variant, size, onClickFn, submit = false, isLoading = false }: CtaButtonProps) => {
  const trackCTA = useTrackCTA();

  return (
    <Button
      type={submit ? 'submit' : 'button'}
      size={size ?? 'medium'}
      variant={variant}
      onClick={() => {
        trackCTA({ cta_position: 'content', text });
        if (onClickFn) onClickFn();
      }}
      isLoading={isLoading}
    >
      {text}
    </Button>
  );
};

export default CtaButton;
