import React from 'react';
import { useLocation } from 'react-router';
import { GridCol, GridRow } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { spacing } from '@naf/theme';
import styled from 'styled-components';
import loadable from '@loadable/component';
import { useGlobalTags, useSiteStructure } from '../hooks/SiteStructure/useSiteStructure';
import { useIsSiteStructureLocationUrlValid } from '../hooks/SiteStructure/useIsSiteStructureLocationUrlValid';
import { LayoutWithMainContent } from '../components/layout/Layout';
import { CategoryAppType } from '../../../types/CategoryAppType';
import { SiteStructureType } from '../../../types/siteStructureType';
import { useCategoryApp } from '../hooks/useCategoryApp';
import FAQ from '../pages/FAQ';
import { DocumentTypeSwitch } from './DocumentTypeSwitch';
import { InternalSearchCardType } from '../components/layout/InternalSearchMetaData';
import { NotFound } from '../loadable-elements/NotFound';

const EmptyMessagesWrapper = styled.div`
  text-align: center;
  padding-top: ${spacing.space48};
  padding-bottom: ${spacing.space64};
  p {
    margin-top: ${spacing.space8};
  }
  h2 {
    margin-bottom: ${spacing.space8};
  }
  div {
    margin: 0 auto;
  }
`;

const CATEGORY_APPS: CategoryAppType['name'][] = [
  'sectionObject',
  'nafCenterApp',
  'elbilApp',
  'twoColumnApp',
  'topicPageApp',
  'articleApp',
  'faqApp',
  'membershipBenefitApp',
  'routePlannerApp',
];

const DynamicApp = loadable(() => import('../pages/DynamicApp/DynamicApp'), {
  resolveComponent: (components) => components.DynamicApp,
});
const LoaderPage = loadable(() => import('../pages/LoaderPage'), {
  resolveComponent: (components) => components.LoaderPage,
});
const TwoColumnApp = loadable(() => import('../pages/TwoColumnApp/TwoColumnApp'), {
  resolveComponent: (components) => components.TwoColumnApp,
});
const TopicPageApp = loadable(() => import('../pages/TopicPageApp/TopicPageApp'), {
  resolveComponent: (components) => components.TopicPageApp,
});
const NafLocationsApp = loadable(() => import('../pages/NafLocationsApp/NafLocationsApp'));

const NafCenterRouter = loadable(() => import('./NafCenterRouter'), {
  resolveComponent: (components) => components.NafCenterRouter,
});

const ArticleList = loadable(() => import('../pages/ArticleList'));
const AllArticlesList = loadable(() => import('../pages/AllArticlesList'));
const SectionPage = loadable(() => import('../pages/SectionPage'), {
  resolveComponent: (components) => components.SectionPage,
});

const CategoryApp = ({ slug, data, app }: { slug: string; data: SiteStructureType; app: CategoryAppType }) => {
  const categoryApp = useCategoryApp(slug, app.name);

  if (!categoryApp) return <LoaderPage />;
  if (categoryApp.error) {
    return (
      <LayoutWithMainContent
        title={data.seoConfig?.title || data.name}
        description={data.seoConfig?.introduction || data.ingress || ''}
        internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
      >
        <main>
          <GridRow>
            <GridCol s="12" m="12" l="12" xl="12">
              <EmptyMessagesWrapper>
                <Text variant={TextVariant.Header3} tag="h2">
                  Det har dessverre oppstått en feil.
                </Text>
                <Text variant={TextVariant.ArticleText}>
                  Vennligst prøv igjen senere, eller kontakt kundeservice dersom problemet vedvarer
                </Text>
              </EmptyMessagesWrapper>
            </GridCol>
          </GridRow>
        </main>
      </LayoutWithMainContent>
    );
  }

  const appData = categoryApp.data;

  if (!appData) return <NotFound />;

  if (appData.name === 'sectionObject') {
    return <SectionPage section={data} appData={appData} />;
  }

  if (appData.name === 'nafCenterApp') {
    return <NafCenterRouter data={data} slug={slug} appData={appData} />;
  }

  if (appData.name === 'membershipBenefitApp') {
    return <DynamicApp data={data} appData={appData} />;
  }

  if (appData.name === 'elbilApp') {
    return <DynamicApp data={data} appData={appData} />;
  }

  if (appData.name === 'routePlannerApp') {
    return <DynamicApp data={data} appData={appData} />;
  }

  if (appData.name === 'articleApp') {
    return <AllArticlesList category={data} />;
  }

  if (appData.name === 'twoColumnApp') {
    return <TwoColumnApp data={data} appData={appData} />;
  }

  if (appData.name === 'topicPageApp') {
    return <TopicPageApp data={data} appData={appData} />;
  }
  if (appData.name === 'faqApp') {
    return <FAQ data={data} appData={appData} />;
  }

  // eslint-disable-next-line no-console
  console.error(`Unhandled app ${app.name}`);

  return null;
};

export const ContentSwitch = ({ match }: { match: { params: { slug: string }; url: string } }) => {
  const { slug } = match.params;
  const location = useLocation();
  const locationStripped = location.pathname.replace(/\/$/, '');
  const basePath = location.pathname.split('/')[1];
  const { meta, data } = useSiteStructure(slug) || {};
  const { data: baseData } = useSiteStructure(basePath) || {};
  const globalTag = useGlobalTags(locationStripped);
  const isValidPath = useIsSiteStructureLocationUrlValid(slug);

  // Validate if location is a globalTag and url found in globalTags list
  const isValid = data?.isGlobalTag && globalTag ? true : isValidPath;

  // if we are on a NAF location subpage, render through NafLocationsApp router
  if (baseData?.app?.name === 'nafLocationsApp') {
    return <NafLocationsApp data={baseData} />;
  }

  // If the content is a category
  if (data && isValid) {
    if (data?.isHiddenCategory && meta?.isUpdating === false) {
      return <NotFound />;
    }
    if (data.app && CATEGORY_APPS.includes(data.app.name)) {
      return <CategoryApp slug={slug} data={data} app={data.app} />;
    }

    return data?.isGlobalTag && globalTag?.data ? (
      <ArticleList category={globalTag.data} />
    ) : (
      <ArticleList category={data} />
    );
  }

  // If the content is a NAF Center article
  if (baseData?.app?.name === 'nafCenterApp') {
    return <NafCenterRouter />;
  }

  // If it is not a category, resolve as an article from common articles endpoint and handle 404s
  return <DocumentTypeSwitch slug={slug} />;
};
