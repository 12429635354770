import React from 'react';
import { TextVariant } from '@naf/text';
import { useHistory } from 'react-router';
import { Button } from '@naf/button';
import { useAuth0 } from '@auth0/auth0-react';
import { useDocumentUrl, useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import { PaywallOption } from '../../../../types/paywallOptionType';
import { PaywallLink } from '../../../../types/paywallLinkType';
import S from './styles';
import { useGTMDataLayer } from '../../hooks/GTM/useGTMDataLayer';

type Props = {
  paywallOptions: PaywallOption;
  paywallLink?: PaywallLink;
  paywallLinkSecondary?: PaywallLink;
};

export const PaywallBox = ({ paywallLink, paywallLinkSecondary, paywallOptions }: Props) => {
  const history = useHistory();
  const primaryUrl = useDocumentUrlWithNode(paywallLink?.data);
  const secondaryURL = useDocumentUrl(paywallLinkSecondary?.data?.slug);
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const dataLayer = useGTMDataLayer();

  const onloginClick = () => {
    dataLayer?.push({
      event: 'cta_click',
      cta_text: 'Logg inn her for tilgang',
      cta_location: history.location.pathname,
      cta_position: 'content',
    });
    loginWithRedirect({ appState: { returnTo: `${window.location.origin}${history.location.pathname}` } });
  };

  return (
    <S.Box>
      {!isAuthenticated && (
        <S.AlreadyAMember>
          <span>Allerede medlem? </span>
          <button type="button" onClick={onloginClick}>
            Logg inn her for tilgang
          </button>
        </S.AlreadyAMember>
      )}
      <S.MainContentWrapper>
        <S.Header variant={TextVariant.Header3}>{paywallOptions.title}</S.Header>
        {paywallOptions.description && <S.TextWrapper>{paywallOptions.description}</S.TextWrapper>}
        <S.ButtonWrapper>
          <Button
            onClick={(e: any) => {
              e.preventDefault();
              window.dataLayer?.push({
                event: 'cta_click',
                cta_text: paywallOptions.ctaText,
                cta_location: history.location.pathname,
                cta_position: 'content',
              });
              history.push(primaryUrl);
            }}
          >
            {paywallOptions.ctaText}
          </Button>
          {paywallOptions.ctaTextSecondary ? (
            <Button
              variant="outline"
              onClick={(e: any) => {
                e.preventDefault();
                window.dataLayer?.push({
                  event: 'cta_click',
                  cta_text: paywallOptions.ctaTextSecondary,
                  cta_location: history.location.pathname,
                  cta_position: 'content',
                });
                history.push(secondaryURL);
              }}
            >
              {paywallOptions.ctaTextSecondary}
            </Button>
          ) : null}
        </S.ButtonWrapper>
      </S.MainContentWrapper>
    </S.Box>
  );
};
