import React from 'react';
import { useLocation } from 'react-router';
import { AccordionList } from '@naf/accordion-list';
import useSelector from '../../../../../redux/typedHooks';
import { AccordionListBlockItemType, AccordionListBlockType } from '../../../../../../../types/AccordionListBlockType';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import { useGTMDataLayer } from '../../../../../hooks/GTM/useGTMDataLayer';
import { NAFGTMEvent } from '../../../../../../../types/GTM/NAFGTMEvent';
import { EventPayload } from '../../../../../../../types/GTM/EventPayload';
import BlockContent from '../../../BlockContent';

export interface AccordionListBlockSerializerProps {
  value: AccordionListBlockType;
}

export const AccordionListBlock = ({ value }: AccordionListBlockSerializerProps) => {
  const listItems: AccordionListBlockItemType[] = [];
  const location = useLocation();
  const dataLayer = useGTMDataLayer();
  const baseUrl = useSelector((state) => state.application.baseUrl);

  const onOpen = (accordionTitle: string) => {
    if (dataLayer) {
      const eventPayload: EventPayload = {
        event: NAFGTMEvent.accordionOpen,
        listValue: `${accordionTitle}`,
        page: location.pathname,
        pageUrl: `${baseUrl}${location.pathname}`,
      };
      dataLayer.push(eventPayload);
    }
  };

  value.accordionList.forEach((item) => {
    const itemContent = <BlockContent value={item.content} />;
    const accordionItem = { title: item.title, content: itemContent, onOpen };
    listItems.push(accordionItem);
  });

  return <AccordionList accordionList={listItems} />;
};

export const AccordionListBlockSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: AccordionListBlockSerializerProps) => (
      <ArticleBlockContentGridCol>
        <AccordionListBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }

  return ({ value }: AccordionListBlockSerializerProps) => <AccordionListBlock value={value} />;
};
