import { breakpoints, spacing } from '@naf/theme';
import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';
import CtaButton from '../../../../../ctaButton/CtaButton';

export const Title = styled.h2`
  font-weight: 900;
  line-height: ${spacing.space56};
  font-size: ${spacing.space48};
  margin-bottom: ${spacing.space32} !important;

  @media (max-width: ${breakpoints.s}) {
    line-height: ${spacing.space40};
    font-size: ${spacing.space32};
    margin-bottom: ${spacing.space20} !important;
  }
`;

export const ProductListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space16};
  * {
    text-decoration: none;
  }
`;

export const ProductContainer = styled.button`
  display: flex;
  flex-direction: row;
  border: none;
  background-color: transparent;
  text-align: left;
  transition: ease 0.2s;
  padding: ${spacing.space24} 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${nafColor.neutral.neutral2};
  }

  &:last-of-type {
    margin-bottom: ${spacing.space16};
  }

  &:hover {
    cursor: pointer;

    h3,
    p {
      color: ${nafColor.primary.park};
      transition: ease 0.2s;
    }

    img {
      transform: scale(1.025);
      transition: transform ease 0.2s;
    }
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space8};
  margin-left: ${spacing.space16};

  p {
    color: ${nafColor.neutral.neutral4};
    weight: 300;
  }
`;

export const ProductImageWrapper = styled.div`
  width: 160px;
  height: 120px;
  overflow: hidden;
  flex-shrink: 0;
  padding-right: ${spacing.space32};

  @media (max-width: ${breakpoints.s}) {
    width: 90px;
    height: 70px;
    padding-right: ${spacing.space8};
  }
`;

export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ProductName = styled.h3`
  font-size: ${spacing.space24};
  line-height: ${spacing.space32};
  font-weight: 700;
  margin-bottom: ${spacing.space8} !important;
`;
export const ProductIngress = styled.p`
  margin-bottom: ${spacing.space16} !important;
`;

export const ConsumerTestCtaWrapper = styled.div`
  margin-top: ${spacing.space20};
  margin-bottom: ${spacing.space32};
`;
export const ConsumerTestCTA = styled(CtaButton)``;
