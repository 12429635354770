import { EventPayload } from '../../../../types/GTM/EventPayload';

export const useGTMDataLayer = (): EventPayload[] | null => {
  if (typeof window !== 'undefined') {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    return window.dataLayer as EventPayload[];
  }
  return null;
};
