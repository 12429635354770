import React from 'react';
import { ModularButton } from '../../../../ModularButton/ModularButton';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import { InternalLinkType } from '../../../../../../../types/internalLinkType';
import { ExternalLinkType } from '../../../../../../../types/externalLinkType';

interface ModularButtonSerializerProps {
  value: {
    primaryButton: {
      buttonText: string;
      variant?: string;
      internalLink?: InternalLinkType;
      externalLink?: ExternalLinkType;
    };
    secondaryButton?: {
      buttonText?: string;
      internalLink?: InternalLinkType;
      externalLink?: ExternalLinkType;
    };
  };
}

export const ModularButtonBlock = ({ value: { primaryButton, secondaryButton } }: ModularButtonSerializerProps) => (
  <ModularButton primaryButton={primaryButton} secondaryButton={secondaryButton} />
);

export const ModularButtonBlockSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: ModularButtonSerializerProps) => (
      <ArticleBlockContentGridCol className="modular-button">
        <ModularButtonBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: ModularButtonSerializerProps) => <ModularButtonBlock value={value} />;
};
