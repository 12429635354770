import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Text, TextVariant } from '@naf/text';
import { Close } from '@styled-icons/material/Close';
import { actions } from '../../redux/modules/globalSettings';
import { actions as userActions } from '../../redux/modules/user';
import BlockContent from '../block-content/BlockContent';
import { GlobalSettingsType } from '../../../../types/globalSettingsType';
import useSelector from '../../redux/typedHooks';
import S from './StyledSystemMessage';

const SystemMessage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const globalSettings: GlobalSettingsType | undefined = useSelector((state) => state.globalSettings?.settings);
  const { showSystemMessage } = useSelector((state) => state.user);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (typeof globalSettings === 'undefined') {
      dispatch(actions.getGlobalSettings.request(''));
    }
  }, [dispatch, globalSettings]);

  useEffect(() => {
    if (typeof globalSettings !== 'undefined' && globalSettings?.announcement) {
      dispatch(userActions.showSystemMessage(true));
    }
  }, [globalSettings, dispatch]);

  const handleCloseSystemMessage = () => {
    dispatch(userActions.showSystemMessage(false));
    return false;
  };

  const toggleMessageBody = () => {
    setIsExpanded(!isExpanded);
  };

  const message = globalSettings?.announcement;
  const shouldShowMessage = message && showSystemMessage && !location.pathname.includes('bli-medlem');

  return shouldShowMessage && showSystemMessage ? (
    <S.MessageWrapper id="system-message" role="region" aria-live="polite">
      <S.InnerWrapper>
        {message.header && (
          <Text variant={TextVariant.ArticleTextHeader} id="system-message-header">
            {message.header}
          </Text>
        )}
        <S.DesktopBodyWrapper>
          <BlockContent value={message.body} />
        </S.DesktopBodyWrapper>
        <S.MobileBodyWrapper>{isExpanded && message.body && <BlockContent value={message.body} />}</S.MobileBodyWrapper>
        <S.StyledButtonLink onClick={toggleMessageBody} text={isExpanded ? 'Les mindre' : 'Les mer'} />
        <S.CloseButton onClick={() => handleCloseSystemMessage()} aria-label="Lukk melding">
          <Close size={24} />
        </S.CloseButton>
      </S.InnerWrapper>
    </S.MessageWrapper>
  ) : null;
};

export default SystemMessage;
