import React, { useRef } from 'react';
import { Text, TextVariant } from '@naf/text';
import { FeaturedVehicleTestResultBlock } from '../../../../../../../../types/featuredVehicleResultBlockType';
import { VehicleResultProps } from '../../../../../SectionBlocks/FeaturedVehicleTestResult';
import { CombinedResultCard } from '../../../../../SectionBlocks/Testshowcase/CombinedResultCard';
import ScrollControl from './ScrollControl/ScrollControl';
import * as S from './Styles';

interface FeaturedVehicleResultSerializerProps {
  value: {
    featuredVehicleResults: FeaturedVehicleTestResultBlock['featuredVehicleResults'];
    text?: string;
    orientation?: 'left' | 'right';
    key: string;
    linksTo: 'top' | 'rangeTests' | 'chargeTests';
  };
}

export const FeaturedVehicleResultBlock: React.FC<FeaturedVehicleResultSerializerProps> = ({ value }) => {
  const { featuredVehicleResults, text, orientation, key, linksTo } = value;

  const scrollContainerRef = useRef(null);

  const itemsRef = useRef(null);
  const getMap = (): Map<any, any> => {
    if (!itemsRef.current) {
      itemsRef.current = new Map();
    }
    return itemsRef.current;
  };

  const vehicleData: VehicleResultProps[] = featuredVehicleResults
    .map(({ testReference }) => ({
      context: {
        testDate: testReference.testDate,
        season: testReference.season,
        tests: testReference.tests,
      },
      data: testReference.data,
    }))
    .filter(({ data }) => data);

  let anchorLink: string | undefined;
  if (linksTo === 'rangeTests') {
    anchorLink = 'rekkeviddetester';
  }
  if (linksTo === 'chargeTests') {
    anchorLink = 'ladetester';
  }

  return (
    <S.StyledArticleBlockContentGridCol $orientation={orientation} $vehicleCount={vehicleData.length}>
      {linksTo === 'top' || typeof linksTo === 'undefined' ? <S.Header>Alle resultater</S.Header> : null}
      {linksTo === 'rangeTests' ? <S.Header>Alle rekkevidderesultater</S.Header> : null}
      {linksTo === 'chargeTests' ? <S.Header>Alle laderesultater</S.Header> : null}
      <S.Container ref={scrollContainerRef} $orientation={orientation} $vehicleCount={vehicleData.length}>
        {/* Don't show text if there is more than one vehicle present */}
        {text && vehicleData.length === 1 && <Text variant={TextVariant.ArticleText}>{text}</Text>}
        {vehicleData.map(({ context, data }) => (
          <CombinedResultCard
            refMapKey={key}
            refMap={getMap}
            key={`${data.vehicleId}_${context.season}`}
            data={data}
            context={context}
            anchorLink={anchorLink}
          />
        ))}
      </S.Container>
      {vehicleData.length > 3 && (
        <S.ScrollWrapper>
          <ScrollControl
            scrollContainerRef={scrollContainerRef}
            scrollItems={vehicleData.map((d) => `${key}_${d.data.vehicleId}`)}
          />
        </S.ScrollWrapper>
      )}
    </S.StyledArticleBlockContentGridCol>
  );
};

export const FeaturedVehicleResultSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: FeaturedVehicleResultSerializerProps) => <FeaturedVehicleResultBlock value={value} />;
  }
  return ({ value }: FeaturedVehicleResultSerializerProps) => <FeaturedVehicleResultBlock value={value} />;
};
