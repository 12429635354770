import React from 'react';
import { SanityBlock } from '@sanity/client';
import { TextVariant } from '@naf/text';
import BlockContent from '../block-content/BlockContent';
import * as S from './Styles';

export enum StepByStepListVariant {
  Secondary = 'secondary',
  Outline = 'outline',
}

const mapVariant = (variant: string): StepByStepListVariant => {
  switch (variant) {
    case 'secondary':
      return StepByStepListVariant.Secondary;
    case 'outline':
    default:
      return StepByStepListVariant.Outline;
  }
};

export interface StepByStepListProps {
  title?: string;
  ingress?: string;
  list: {
    _key?: string;
    title?: string;
    body?: SanityBlock[];
  }[];
  variant?: string;
}

export const StepByStepList = ({
  title,
  ingress,
  list,
  variant = StepByStepListVariant.Outline,
}: StepByStepListProps) => {
  const onlyTitles = list.every((i) => i.title && (!i.body || i.body.length === 0));

  return (
    <S.Container $variant={mapVariant(variant)} $onlyTitles={onlyTitles}>
      {title && <S.Title variant={TextVariant.Header3}>{title}</S.Title>}
      {ingress && <S.Ingress>{ingress}</S.Ingress>}
      {onlyTitles ? (
        // Apply different rendering when body has no content.
        <ol>
          {list.map((i) => (
            <li key={`step-${i?._key || i.title}`}>
              <S.Step>{i.title}</S.Step>
            </li>
          ))}
        </ol>
      ) : (
        // Default rendering when body is present.
        <ol>
          {list.map((i) => (
            <li key={`step-${i?._key || i.title}`}>
              <S.ListContent>
                {i.title && i.body && (
                  <>
                    <S.Step>{i.title}</S.Step>
                    <BlockContent value={i.body} />
                  </>
                )}
                {!i.title && i.body && (
                  <S.BodyWrapper>
                    <BlockContent value={i.body} />
                  </S.BodyWrapper>
                )}
              </S.ListContent>
            </li>
          ))}
        </ol>
      )}
    </S.Container>
  );
};

export default StepByStepList;
