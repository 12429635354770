import useSelector from '../redux/typedHooks';
import { useDocument } from './useDocument';
import { DocumentTypeLabel, TestReferenceComponent } from '../../../types/testType';
import { ArticleType } from '../../../types/articleType';
import { DataState } from '../redux/modules/sitestructure';
import { SiteStructureType } from '../../../types/siteStructureType';
import { MappedData } from '../redux/modules/documents';
import { TopicPageAppPropertiesType } from '../../../types/CategoryAppType';
import { BenefitOverviewType } from '../../../types/BenefitsOverviewType';

// merge document types and reference document types
type Data =
  | MappedData['data']
  | {
      type: DocumentTypeLabel.myNafSubPage;
      slug: string;
    }
  | {
      type: DocumentTypeLabel.vehicleDataModel;
      slug: string;
    }
  | TestReferenceComponent
  | {
      type: DocumentTypeLabel.nafnoSiteStructure;
      slug: string;
    }
  | {
      type: DocumentTypeLabel.myNafFrontpage;
      slug: string;
    }
  | {
      type: DocumentTypeLabel.travelSuggestion;
      slug: string;
      parent: SiteStructureType;
    }
  | {
      type: DocumentTypeLabel.abTestExperiment;
      slug: string;
    }
  | {
      type: DocumentTypeLabel.benefitOverview;
      slug: string;
      parameters?: string;
    };

const isArticle = (variableToCheck: any): variableToCheck is ArticleType =>
  (variableToCheck as ArticleType).type === DocumentTypeLabel.nafnoArticle ||
  (variableToCheck as ArticleType).type === DocumentTypeLabel.nafnoNewsArticle;

const getCategoryUrl = (
  data: ArticleType | TopicPageAppPropertiesType | TestReferenceComponent,
  mappedCategories: Record<string, DataState<SiteStructureType>>,
) => {
  const mappedCategory = data.category?.slug ? mappedCategories[data.category?.slug]?.data : null;

  // If node has no category, check for parent article
  const mappedParentCategory =
    !mappedCategory && data.parent?.category?.slug ? mappedCategories[data.parent.category.slug]?.data : null;

  let fullCategoryUrl: string;

  if (mappedCategory || mappedParentCategory) {
    fullCategoryUrl = mappedParentCategory
      ? `${mappedParentCategory?.url}/${data.parent?.slug}/${data.slug}`
      : `${mappedCategory?.url}/${data.slug}`;
  }

  // Underartikkel under naf senter produktartikkel
  if (data.parent?.type === DocumentTypeLabel.nafCenterProductArticle) {
    fullCategoryUrl = `/bilverksted-og-tester/${data.parent.slug}/${data.slug}`;
  }

  return fullCategoryUrl;
};

export const resolveDocumentDataUrl = (
  documentData?: Data,
  categoryData?: Record<string, DataState<SiteStructureType>>,
) => {
  if (!documentData) return undefined;

  if (documentData.type === DocumentTypeLabel.travelSuggestion) {
    return `${categoryData[documentData.parent.slug]?.data?.url}/${documentData.slug}`;
  }

  if (documentData.type === DocumentTypeLabel.abTestExperiment) {
    return `/${documentData.slug}`;
  }

  if (isArticle(documentData)) {
    // Underartikkel under medlemsfordel
    if (documentData.parent?.type === DocumentTypeLabel.nafMembershipBenefit) {
      return `/medlemskap/medlemsfordeler/${documentData.parent.slug}/${documentData.slug}`;
    }

    // Underartikkel under produktartikkel
    if (documentData.parent?.type === DocumentTypeLabel.nafnoProductArticle) {
      // Underartikkel under medlemsfordel 'medlemskap' (rot)
      if (documentData.parent?.slug === 'medlemskap') {
        return `/medlemskap/${documentData.slug}`;
      }
      return `/medlemskap/${documentData.parent.slug}/${documentData.slug}`;
    }

    // Underartikkel under naf senter produktartikkel
    if (documentData.parent?.type === DocumentTypeLabel.nafCenterProductArticle) {
      return `/bilverksted-og-tester/${documentData.parent.slug}/${documentData.slug}`;
    }

    // Underartikkel under lokalavdeling
    if (documentData.parent?.type === DocumentTypeLabel.localDepartment) {
      return `/her-finner-du-naf/lokalavdelinger/${documentData.parent.slug}/artikkel/${documentData.slug}`;
    }

    // Underartikkel under mcclub
    if (documentData.parent?.type === DocumentTypeLabel.mcClub) {
      return `/her-finner-du-naf/mc-klubber/${documentData.parent.slug}/artikkel/${documentData.slug}`;
    }

    return getCategoryUrl(documentData, categoryData);
  }

  if (documentData.type === DocumentTypeLabel.benefitOverview) {
    return `/medlemskap/medlemsfordeler?${(documentData as BenefitOverviewType).parameters}`;
  }

  if (
    documentData.type === DocumentTypeLabel.articleRangeReferenceComponent ||
    documentData.type === DocumentTypeLabel.articleChargeReferenceComponent
  ) {
    return `/bilguiden/bilmodell/${(documentData as TestReferenceComponent).vehicle?.slug}/${documentData.slug}`;
  }

  if (documentData.type === DocumentTypeLabel.nafMembershipBenefit) {
    /*
    TODO currently local benefits don't return parent, so we handle this in the component
    // lokal medlemsfordel
    if (data.parent?.type === DocumentTypeLabel.localDepartment) {
      return `/her-finner-du-naf/lokalavdelinger/${data.parent.slug}/fordeler/${data.slug}`;
    }
    if (data.parent?.type === DocumentTypeLabel.mcClub) {
      return `/her-finner-du-naf/mc-klubber/${data.parent.slug}/fordeler/${data.slug}`;
    } */
    return `/medlemskap/medlemsfordeler/${documentData.slug}`;
  }

  if (documentData.type === DocumentTypeLabel.nafCenterArticle) {
    return `/bilverksted-og-tester/naf-senter/${documentData.slug}`;
  }

  if (documentData.type === DocumentTypeLabel.nafCenterProductArticle) {
    return `/bilverksted-og-tester/${documentData.slug}`;
  }

  if (documentData.type === DocumentTypeLabel.topicPage || documentData.type === DocumentTypeLabel.productPage) {
    if (documentData.category) {
      return getCategoryUrl(documentData, categoryData);
    }
    if (documentData.parent?.type === 'nafnoSiteStructure' && documentData.parent.slug) {
      return `${categoryData[documentData.parent.slug]?.data?.url || ''}/${documentData.slug}`;
    }
    return `/${documentData.slug}`;
  }

  if (documentData.type === DocumentTypeLabel.nafnoProductArticle) {
    if (documentData.slug === 'medlemskap') {
      return '/medlemskap';
    }
    if (documentData.parent?.type === DocumentTypeLabel.nafnoProductArticle) {
      return `/${documentData.parent.slug}/${documentData.slug}`;
    }
    return `/${documentData.slug}`;
  }

  if (documentData?.type === DocumentTypeLabel.testReferenceComponent) {
    return getCategoryUrl(documentData, categoryData);
  }

  if (documentData?.type === DocumentTypeLabel.nafnoSiteStructure) {
    return categoryData[documentData.slug]?.data?.url;
  }

  if (documentData?.type === DocumentTypeLabel.myNafFrontpage) {
    return `/${documentData?.slug}`;
  }

  if (documentData.type === DocumentTypeLabel.vehicleDataModel) {
    return `/bilguiden/bilmodell/${documentData.slug}`;
  }

  if ((documentData as any).vehicle?.slug) {
    return `/bilguiden/bilmodell/${(documentData as any).vehicle?.slug}`;
  }

  // faq
  if (documentData.type === DocumentTypeLabel.faq) {
    return `/kundeservice/${documentData.slug}`;
  }

  // mitt naf
  if (documentData.type === DocumentTypeLabel.myNafSubPage) {
    return `/mitt-naf/${documentData.slug}`;
  }

  // lokalavdeling
  if (documentData.type === DocumentTypeLabel.localDepartment) {
    return `/her-finner-du-naf/lokalavdelinger/${documentData.slug}`;
  }
  if (documentData.type === DocumentTypeLabel.mcClub) {
    return `/her-finner-du-naf/mc-klubber/${documentData.slug}`;
  }
  return undefined;
};

const useResolveDataUrl = (data?: Data, anchorId?: string, queryParams?: { key: string; value: string }[]) => {
  const { mappedCategories } = useSelector((state) => state.sitestructure);
  let href = resolveDocumentDataUrl(data, mappedCategories);
  if (queryParams && queryParams?.length > 0 && URLSearchParams) {
    const queryParamsObject = new URLSearchParams();
    queryParams.forEach(({ key, value }) => {
      queryParamsObject.append(key, value);
    });
    href = `${href}?${queryParamsObject.toString()}`;
  }

  if (anchorId) {
    href = `${href}#${anchorId}`;
  }

  // Allow for product articles to be nested under site structure / categories
  if (
    data?.type === DocumentTypeLabel.nafnoProductArticle &&
    data.parent?.type === DocumentTypeLabel.nafnoSiteStructure &&
    data.parent?.slug
  ) {
    href = `/${data.parent.slug + href}`;
  }

  return href;
};

// Get the url of the document with the given slug
export const useDocumentUrl = (slug: string) => {
  const { data } = useDocument(slug);
  return useResolveDataUrl(data);
};

// If we already have the data, and the node
export const useDocumentUrlWithNode = (data: any, anchorId?: string, queryParams?: { key: string; value: string }[]) =>
  useResolveDataUrl(data, anchorId, queryParams);
