/* eslint-disable camelcase */
import { EspialEventTypes } from '../../../../types/Espial/EspialEventTypes';
import { VehicleObject } from '../../../../types/myVehiclesType';

const useEspial = () => {
  const createEventPayload = (params?: Record<string, any>[] | null) => ({
    props: Object.fromEntries(
      (Array.isArray(params) ? params : [])
        .filter((obj) => obj && typeof obj === 'object')
        .flatMap((obj) => Object.entries(obj)),
    ),
  });

  const createPurchaseEventPayload = (
    items: {
      number: number;
      productName: string;
      productNumber: string;
      productPrice: number;
    }[],
    isFirstPurchase: boolean,
  ) => {
    const getSku = (productNumber: string) => {
      switch (productNumber) {
        case '1111-2010':
        case '1111-2020':
          return '1111';
        case '1121-2010':
        case '2010-1121':
          return '1121';
        default:
          return productNumber;
      }
    };

    return {
      revenue: items.map((item) => ({
        amount: item.productPrice,
        currency: 'NOK',
        id: item.productName,
        quantity: item.number,
        sku: getSku(item.productNumber),
      })),
      checkout_name: isFirstPurchase ? 'bli-medlem' : 'mitt-naf',
    };
  };

  const trackEvent = (eventType: EspialEventTypes, params?: Record<string, any>[]) => {
    if (typeof window === 'undefined') {
      return;
    }

    window?.espial?.trigger(eventType, createEventPayload(params));
  };

  const trackPurchase = (
    items: {
      number: number;
      productName: string;
      productNumber: string;
      productPrice: number;
    }[],
    isFirstPurchase: boolean,
  ) => {
    if (typeof window === 'undefined') {
      return;
    }

    window?.espial?.trigger(EspialEventTypes.PURCHASE, createPurchaseEventPayload(items, isFirstPurchase));
  };

  const trackVehicleAdded = (vehicle: VehicleObject) => {
    trackEvent(EspialEventTypes.VEHICLE_ADDED, [
      { vehicle_type: vehicle.vehicleType },
      { vehicle_brand: vehicle.manufactureName },
      { vehicle_model: vehicle.modelName },
      { vehicle_year: vehicle.year },
      { vehicle_is_electric: vehicle.isEv },
    ]);
  };

  const trackVehicleRemoved = (vehicle: VehicleObject) => {
    trackEvent(EspialEventTypes.VEHICLE_REMOVED, [
      { vehicle_type: vehicle.vehicleType },
      { vehicle_brand: vehicle.manufactureName },
      { vehicle_model: vehicle.modelName },
      { vehicle_year: vehicle.year },
      { vehicle_is_electric: vehicle.isEv },
    ]);
  };

  const trackNafCenterReportOpened = () => {
    trackEvent(EspialEventTypes.NAF_CENTER_REPORT_OPENED);
  };

  const trackVehicleContractOpened = () => {
    trackEvent(EspialEventTypes.VEHICLE_CONTRACT_OPENED);
  };

  const trackLogin = () => {
    trackEvent(EspialEventTypes.LOGIN);
  };

  const trackLogout = () => {
    trackEvent(EspialEventTypes.LOGOUT);
  };

  const trackMenuClick = (params: Record<string, any>[]) => {
    trackEvent(EspialEventTypes.MENU_CLICK, params);
  };

  const trackMembershipUpgraded = () => {
    trackEvent(EspialEventTypes.MEMBERSHIP_UPGRADED);
  };

  const trackHouseholdMemberAdded = () => {
    trackEvent(EspialEventTypes.HOUSEHOLD_MEMBER_ADDED);
  };

  return {
    createEventPayload,
    createPurchaseEventPayload,
    trackEvent,
    trackPurchase,
    trackVehicleAdded,
    trackVehicleRemoved,
    trackNafCenterReportOpened,
    trackVehicleContractOpened,
    trackLogin,
    trackLogout,
    trackMenuClick,
    trackMembershipUpgraded,
    trackHouseholdMemberAdded,
  };
};

export default useEspial;
