import { SectionBlockType } from './CategoryAppType';
import { ElPrixContextData } from './ElPrixContextData';
import { CloudinaryImageType } from './blockTypes';
import { ChargeTest, RangeTest } from './carModelType';
import { VehicleCategory } from './testType';

export type ShowcaseBlockType = {
  title: string;
  ingress?: string;
  data: ElPrixContextData;
  extraContent?: SectionBlockType[];
  allCategories: VehicleCategory[];
};

export type VehicleTestData = {
  vehicleName: string;
  name: string;
  displayName: string;
  price?: number;
  vehicleId: string;
  slug:
    | string
    | {
        current: string;
      };
  categories: VehicleCategory[] | null;
  testData: {
    image: CloudinaryImageType | null;
    // Exclude fields with BlockContentProps as type as it breaks ShowcaseBlockType
    rangeTest: Omit<RangeTest, 'rangeTestSummary' | 'rangeTestResultSummary' | 'batteryDrainageSummary'> | null;
    chargeTest: Omit<ChargeTest, 'chargeTestSummary' | 'chargeTestResultSummary'> | null;
  };
};

export const emptyVehicle: VehicleTestData = {
  slug: '',
  name: '',
  displayName: '',
  vehicleId: '',
  testData: {
    rangeTest: {
      key: '',
      vehicleModelReference: null,
      type: 'rangeTest',
      altitudeAtStopMeters: null,
      context: null,
      statedRangeKm: null,
      measuredRangeKm: null,
      statedConsumptionKWhper100km: null,
      measuredConsumptionKWhper100km: null,
      measuredDistanceAt10PercentSoc: null,
    },
    image: null,
    chargeTest: null,
  },
  vehicleName: '',
  categories: [],
};
