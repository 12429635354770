import React, { useEffect, useMemo, useState } from 'react';
import { Text, TextVariant } from '@naf/text';
import styled, { css } from 'styled-components';
import { useIntersectionObserver } from 'usehooks-ts';
import { breakpoints, spacing } from '@naf/theme';
import { PortableText } from '@portabletext/react';
import useSelector from '../../redux/typedHooks';
import { ProsAndCons } from '../../components/bilguide/ProsAndCons';
import { HeaderText } from './HeaderText';
import { ModelImageCarousel } from './ModelImageCarousel';
import { SafetyTest } from '../../components/bilguide/carTest/SafetyTest';
import { RangeTests } from './RangeTests';
import { ChargeTests } from './ChargeTests';
import { MotorTest } from './MotorTest';
import { TestChips } from './TestChips';
import { SectionsNavigation } from './SectionsNavigation';
import { VehicleData } from './VehicleData';
import { NeighbouringModelNav } from './NeighbouringModelNav';
import { TrunkTests } from './TrunkTests';
import { ColumnBlock, ColumnBlockHeader, ColumnContainer } from '../../components/SectionBlocks/ColumnBlock';
import RelatedArticleList from '../../components/related-articles/RelatedArticleList';
import FeedbackSurvey from '../../components/feedbacksurvey/FeedbackSurvey';
import { useWindowLocation } from '../../hooks/Window/useWindowLocation';
import { ArticleCard } from '../../components/SectionBlocks/ColumnContent/VerticalArticleBlock';
import { RelatedModels } from './RelatedModels';
import { BlockContentWrapper } from './BlockContentWrapper';
import { LoaderContent } from '../LoaderPage';
import { Season } from '../../../../types/ElPrixContextData';

interface Props {
  slug: string;
}

export type SectionKey = 'model' | 'about' | 'rangeTest' | 'chargeTest' | 'trunkTest' | 'safetyTest' | 'other';
export type ScrollSection = {
  key: SectionKey;
  label: string;
  isPresent: boolean;
  ref: React.RefObject<HTMLDivElement>;
  content: React.ReactNode;
};

const ModelDetailPage = ({ slug }: Props) => {
  const path = useWindowLocation();
  const stateData = useSelector((state) => state.cars.mappedData[slug]);
  const [currentComponentInView, setComponentInView] = useState<SectionKey>('about');
  const moreContent = stateData?.data?.meta?.globalContent?.moreContent || [];

  const scrollSections = useMemo(() => {
    const { meta, vehicleModelPictures, motorTests, safetyTest } = stateData.data || {};

    const scrollToSection = (sectionKey: SectionKey) => {
      const { ref } = scrollSections.find(({ key }) => sectionKey === key);

      if (ref) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    const handleTestChipClick = (testDate: string, season: Season) => {
      scrollToSection('rangeTest');
      window.dispatchEvent(
        new CustomEvent('selectRangeTest', {
          detail: { testDate, season },
        }),
      );
    };

    const scrollToMotorTest = () => {
      const motorTestElement = document.getElementById('motorTest');
      if (motorTestElement) {
        motorTestElement.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return meta
      ? ([
          {
            key: 'model',
            label: meta?.name,
            isPresent: true,
            content: (
              <>
                <HeaderText categories={meta?.categories} title={meta?.name} ingress={meta?.ingress} />
                <TestChips
                  rangeTests={meta?.rangeTests}
                  chargeTests={meta?.chargeTests}
                  testedByMotor={motorTests?.length > 0}
                  onNafTestClick={(testDate, season) => handleTestChipClick(testDate, season)}
                  onMotorTestClick={() => scrollToMotorTest()}
                />
                <ModelImageCarousel
                  ofvImages={meta?.cloudinaryImages ? [] : vehicleModelPictures}
                  sanityImages={meta?.cloudinaryImages}
                />
              </>
            ),
          },
          {
            key: 'about',
            label: 'Generelt om modellen',
            isPresent: true,
            content: (
              <>
                <VehicleData
                  onClick={scrollToSection}
                  data={{ ...stateData?.data, chargeTests: meta?.chargeTests, rangeTests: meta?.rangeTests }}
                />
                {stateData?.data?.meta?.body && (
                  <BlockContentWrapper>
                    <PortableText value={meta?.body} />
                  </BlockContentWrapper>
                )}
                <ProsAndCons pros={meta?.prosAndCons?.pros} cons={meta?.prosAndCons?.cons} />
                <MotorTest motorTest={motorTests?.[0]} />
              </>
            ),
          },
          {
            key: 'rangeTest',
            label: 'Test av rekkevidde',
            isPresent: meta?.rangeTests?.length > 0,
            content: (
              <RangeTests
                slug={slug}
                rangeTestInfoBox={meta?.globalContent?.rangeTestInfoBox}
                rangeTests={meta?.rangeTests}
                cloudinaryImages={meta?.cloudinaryImages}
                chargeTests={meta?.chargeTests}
              />
            ),
          },
          {
            key: 'chargeTest',
            label: 'Test av lading',
            isPresent: meta?.chargeTests?.length > 0,
            content: (
              <ChargeTests
                slug={slug}
                chargeTestInfoBox={meta?.globalContent?.chargeTestInfoBox}
                chargeTests={meta?.chargeTests}
                cloudinaryImages={meta?.cloudinaryImages}
                rangeTests={meta?.rangeTests}
              />
            ),
          },
          {
            key: 'trunkTest',
            label: 'Test av bagasjerom',
            isPresent: meta?.trunkTests?.length,
            content: <TrunkTests slug={slug} trunkTests={meta?.trunkTests} />,
          },
          {
            key: 'safetyTest',
            label: 'Bilens sikkerhet',
            isPresent: safetyTest,
            content: (
              <>
                <SectionHeader variant={TextVariant.Header1} tag="h2">
                  Bilens sikkerhet
                </SectionHeader>
                <SafetyTest safetyTest={safetyTest} />
              </>
            ),
          },
          {
            key: 'other',
            label: 'Andre tester, tips og råd',
            isPresent: meta?.relatedContent?.length > 0,
            content: (
              <RelatedContentWrapper>
                <SectionHeader variant={TextVariant.Header1} tag="h2">
                  Andre tester, tips og råd
                </SectionHeader>
                <RelatedArticleList relatedContent={meta?.relatedContent} />
              </RelatedContentWrapper>
            ),
          },
        ]
          .filter(({ isPresent }) => isPresent)
          .map((d) => ({
            ...d,
            ref: React.createRef<HTMLDivElement>(),
          })) as ScrollSection[])
      : [];
  }, [slug, stateData?.data]);

  useEffect(() => {}, [currentComponentInView]);

  const scrollTo = (sectionKey: SectionKey) => {
    const { ref } = scrollSections.find(({ key }) => sectionKey === key);

    if (ref) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (!stateData?.data?.meta) {
    return <LoaderContent />;
  }

  return (
    <Container>
      <Main>
        {scrollSections.map(({ key, content, ref }, i) => (
          <ScrollComponent index={i} key={key} fireOnInView={() => setComponentInView(key)} observerRef={ref}>
            {content}
          </ScrollComponent>
        ))}
        <Section $isBottomElement>
          <SectionContent $unset>
            <NeighbouringModelNav currentSlug={slug} />
          </SectionContent>
        </Section>
        <Section $isBottomElement>
          <SectionContent $unset $relative>
            <RelatedModels currentSlug={slug} inSidebar={false} />
          </SectionContent>
        </Section>
        <Section>
          <SectionContent>
            {moreContent?.map((block, i) => (
              <ColumnBlock
                key={block.title || i}
                headerVariant="large"
                block={block}
                activateContextThemeSwitch={false}
              />
            ))}
            {moreContent?.length > 0 && <FeedbackMargin />}
            <FeedbackSurvey
              title="Hvordan var innholdet på denne siden?"
              customText={{
                positiveRadioLabel: 'Bra',
                negativeRadioLabel: 'Dårlig',
                positiveTextAreaPlaceholder: 'Så bra! Er det noe vi kunne ha gjort enda bedre?',
                negativeTextAreaPlaceholder: 'Det var dumt. Kan du fortelle hva du savnet eller håpet hadde vært her?',
              }}
              contentUrl={path}
            />
          </SectionContent>
        </Section>
      </Main>
      <Aside>
        <SectionsNavigation
          slug={slug}
          modelName={stateData?.data?.meta?.name}
          navigateToSection={scrollTo}
          sections={scrollSections}
          sectionInView={currentComponentInView}
        />
      </Aside>
    </Container>
  );
};

type ScrollComponentProps = {
  children?: React.ReactNode;
  fireOnInView: () => void;
  observerRef: React.RefObject<HTMLDivElement>;
  index: number;
};

const ScrollComponent = ({ children, fireOnInView, observerRef, index }: ScrollComponentProps) => {
  const dataRef = useIntersectionObserver(observerRef, {});

  useEffect(() => {
    if (dataRef?.isIntersecting) {
      fireOnInView();
    }
  }, [dataRef, fireOnInView]);

  return (
    <Section $isTopElement={index === 0} ref={observerRef}>
      <SectionContent>{children}</SectionContent>
    </Section>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.border.subtle};

  @media (max-width: ${breakpoints.l}) {
    flex-direction: column;
    border-top: 0;
  }
`;

const Section = styled.section<{ $isTopElement?: boolean; $isBottomElement?: boolean }>`
  padding: ${({ $isBottomElement }) => ($isBottomElement ? 0 : spacing.space64)} 0;
  width: 100%;
  scroll-margin-top: ${({ $isTopElement }) => ($isTopElement ? '200px' : '20px')};
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.border.subtle};

  @media (max-width: ${breakpoints.l}) {
    padding: ${({ $isBottomElement }) =>
      $isBottomElement ? `0 ${spacing.space24}` : `${spacing.space48} ${spacing.space24}`};
  }
`;

const Main = styled.div`
  max-width: 1520px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.border.subtle};

  @media (max-width: ${breakpoints.l}) {
    margin-top: 62px;
    border-left: 0;
  }

  ${Section}:last-child {
    border-bottom: 0 !important;
    padding-bottom: ${spacing.space120};

    @media (max-width: ${breakpoints.l}) {
      padding-bottom: ${spacing.space48};
    }
  }
`;

const SectionContent = styled.div<{ $unset?: boolean; $relative?: boolean }>`
  position: ${({ $relative }) => ($relative ? 'relative' : 'static')};
  max-width: 736px;
  flex-grow: 1;
  flex-basis: 100%;

  @media (max-width: ${breakpoints.l}) {
    ${({ $unset }) =>
      $unset &&
      css`
        max-width: $unset;
      `}
    width: calc(100vw - 48px);
  }

  ${ColumnContainer} {
    ${ColumnBlockHeader} {
      margin-bottom: 46px;
      @media (max-width: ${breakpoints.m}) {
        margin-bottom: 38px;
      }
    }
    ${ArticleCard} {
      /* LinkCards have an outline and these don't, so they don't vertically align */
      margin-top: 2px;
      border-radius: 3px;
      overflow: hidden;
    }
  }
`;

export const SectionHeader = styled(Text)`
  margin: 0;
  margin-bottom: ${spacing.space48};

  @media (max-width: ${breakpoints.l}) {
    margin-bottom: ${spacing.space40};
  }
`;

const Aside = styled.aside`
  max-width: 400px;
  min-width: 240px;
  width: 25vw;

  @media (max-width: ${breakpoints.l}) {
    position: absolute;
    min-width: unset;
    border-right: 0;
    min-height: unset;
    margin-left: -24px;
    max-width: unset;
    width: 100vw;
    z-index: 2;
  }
`;

const FeedbackMargin = styled.div`
  margin-top: ${spacing.space32};

  @media (max-width: ${breakpoints.l}) {
    ${spacing.space16};
  }
`;

const RelatedContentWrapper = styled.div`
  li:last-child .card {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

export { ModelDetailPage };
