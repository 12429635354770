import { Action, ActionType, createAction, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { iif, Observable, of, OperatorFunction, throwError } from 'rxjs';
import { catchError, concatMap, delay, map, mergeMap, retryWhen, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import { ajax } from 'rxjs/ajax';
import { Boom } from '@hapi/boom';

import {
  GetProductsResponse,
  MyProfileForm,
  PostActivationOrderError,
  PostActivationOrderRequest,
  PostActivationOrderResponse,
  PostPaymentOrderError,
  PostPaymentOrderRequest,
  PostPaymentOrderResponse,
  PostProductUpdateError,
  PostProductUpdateRequest,
  PostProductUpdateResponse,
  ProductType,
  ProfileForm,
} from '@dtp/membership-service-types';

import { TypedEpic } from '../types';
import { CustomerInformationResponse } from '../../../../types/customerInformationType';

// Action types
export enum Actions {
  GET_MY_MEMBERSHIP = 'naf/myMembership/GET_MY_MEMBERSHIP',
  GET_MY_MEMBERSHIP_SUCCESS = 'naf/myMembership/GET_MY_MEMBERSHIP_SUCCESS',
  GET_MY_MEMBERSHIP_FAIL = 'naf/myMembership/GET_MY_MEMBERSHIP_FAIL',
  GET_MY_MEMBERSHIP_CANCEL = 'naf/myMembership/GET_MY_MEMBERSHIP_CANCEL',
  ACTIVATE_PRODUCT = 'naf/myMembership/ACTIVATE_PRODUCT',
  ACTIVATE_PRODUCT_SUCCESS = 'naf/myMembership/ACTIVATE_PRODUCT_SUCCESS',
  ACTIVATE_PRODUCT_FAIL = 'naf/myMembership/ACTIVATE_PRODUCT_FAIL',
  ACTIVATE_PRODUCT_CANCEL = 'naf/myMembership/ACTIVATE_PRODUCT_CANCEL',
  PURCHASE_PRODUCT = 'naf/myMembership/PURCHASE_PRODUCT',
  PURCHASE_PRODUCT_SUCCESS = 'naf/myMembership/PURCHASE_PRODUCT_SUCCESS',
  PURCHASE_PRODUCT_FAIL = 'naf/myMembership/PURCHASE_PRODUCT_FAIL',
  PURCHASE_PRODUCT_CANCEL = 'naf/myMembership/PURCHASE_PRODUCT_CANCEL',
  UPDATE_PRODUCT = 'naf/myMembership/UPDATE_PRODUCT',
  UPDATE_PRODUCT_SUCCESS = 'naf/myMembership/UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_FAIL = 'naf/myMembership/UPDATE_PRODUCT_FAIL',
  UPDATE_PRODUCT_CANCEL = 'naf/myMembership/UPDATE_PRODUCT_CANCEL',
  GET_CUSTOMER_INFORMATION = 'naf/myMembership/GET_CUSTOMER_INFORMATION',
  GET_CUSTOMER_INFORMATION_SUCCESS = 'naf/myMembership/GET_CUSTOMER_INFORMATION_SUCCESS',
  GET_CUSTOMER_INFORMATION_FAIL = 'naf/myMembership/GET_CUSTOMER_INFORMATIONS_FAIL',
  GET_CUSTOMER_INFORMATION_CANCEL = 'naf/myMembership/GET_CUSTOMER_INFORMATION_CANCEL',
  CONFIRM_ORCHESTRATION_IN_LOCAL_STORAGE = 'naf/myMembership/CONFIRM_ORCHESTRATION_IN_LOCAL_STORAGE',
  CLEAR_GENERIC_SERVER_ERROR_MESSAGE = 'naf/myMembership/CLEAR_GENERIC_SERVER_ERROR_MESSAGE',
}

export enum OrderActionType {
  ProductActivation = 'ProductActivation',
  ProductPurchase = 'ProductPurchase',
  ProductUpdate = 'ProductUpdate',
}

// State type
export interface State {
  orchestrationIds: Record<
    PostPaymentOrderResponse['orchestrationId'] | PostActivationOrderResponse['orchestrationId'],
    { date?: string; isAddedToSessionStorage: boolean; productId?: string; type?: OrderActionType }
  >;
  products: {
    meta: { isUpdating: boolean; fetchState: string };
    ownedProducts: Record<
      ProductType['productNumber'],
      ProductType & {
        meta?: {
          isUpdating: boolean;
          error?: PostProductUpdateError['validationResults'];
          orchestrationId?: PostPaymentOrderResponse['orchestrationId'];
        };
        update?: PostProductUpdateRequest;
      }
    >;
    eligibleProducts: Record<
      ProductType['productNumber'],
      ProductType & {
        meta?: {
          isUpdating: boolean;
          orchestrationId?: PostPaymentOrderResponse['orchestrationId'];
        };
      }
    >;
    householdDetails?: {
      activationPrice: number;
      renewalPrice: number;
      discount: number;
      nextInvoiceDate: string;
      includedProductIds: ProductType['productNumber'][];
    };
    campaignCodeDetails?: GetProductsResponse['customerProducts']['campaignCodeDetails'];
  };
  myProfileForm?: MyProfileForm;
  householdMemberForm?: ProfileForm;
  customerInformation: {
    data: CustomerInformationResponse;
    meta: { fetchState: 'initial' | 'loading' | 'success' | 'error' };
  };
  meta: {
    isUpdating: boolean;
  };
  order?: PostPaymentOrderResponse;
  activation?: PostActivationOrderResponse;
  errorState?: any;
}

// Initial state
export const initialState: State = {
  orchestrationIds: {},
  products: {
    meta: { isUpdating: false, fetchState: 'initial' },
    ownedProducts: {},
    eligibleProducts: {},
  },
  myProfileForm: undefined,
  householdMemberForm: undefined,
  customerInformation: {
    data: {
      canAddHouseholdMembers: false,
      customerId: '',
      customerNumber: '',
      customer: undefined,
      membership: undefined,
      numberOfPersonsInHousehold: undefined,
      relatedCustomers: [],
      services: [],
      households: [],
    },
    meta: { fetchState: 'initial' },
  },
  meta: {
    isUpdating: false,
  },
};

// Actions
export const actions = {
  getMyMembership: createAsyncAction(
    Actions.GET_MY_MEMBERSHIP, // request payload creator
    Actions.GET_MY_MEMBERSHIP_SUCCESS, // success payload creator
    Actions.GET_MY_MEMBERSHIP_FAIL, // failure payload creator
    Actions.GET_MY_MEMBERSHIP_CANCEL, // optional cancel payload creator
  )<[string, { campaignCode?: string }], GetProductsResponse, Error, undefined>(),
  productActivation: createAsyncAction(
    Actions.ACTIVATE_PRODUCT,
    Actions.ACTIVATE_PRODUCT_SUCCESS,
    Actions.ACTIVATE_PRODUCT_FAIL,
    Actions.ACTIVATE_PRODUCT_CANCEL,
  )<
    [PostActivationOrderRequest, { token?: string }],
    PostActivationOrderResponse,
    PostActivationOrderError,
    undefined
  >(),
  productPurchase: createAsyncAction(
    Actions.PURCHASE_PRODUCT,
    Actions.PURCHASE_PRODUCT_SUCCESS,
    Actions.PURCHASE_PRODUCT_FAIL,
    Actions.PURCHASE_PRODUCT_CANCEL,
  )<[PostPaymentOrderRequest, { token?: string }], PostPaymentOrderResponse, PostPaymentOrderError, undefined>(),
  productUpdate: createAsyncAction(
    Actions.UPDATE_PRODUCT,
    Actions.UPDATE_PRODUCT_SUCCESS,
    Actions.UPDATE_PRODUCT_FAIL,
    Actions.UPDATE_PRODUCT_CANCEL,
  )<
    [PostProductUpdateRequest, { token?: string }],
    [PostProductUpdateResponse, { request: PostProductUpdateRequest }],
    [PostProductUpdateError, { request: PostProductUpdateRequest }],
    [undefined, { request: PostProductUpdateRequest }]
  >(),
  getCustomerInformation: createAsyncAction(
    Actions.GET_CUSTOMER_INFORMATION,
    Actions.GET_CUSTOMER_INFORMATION_SUCCESS,
    Actions.GET_CUSTOMER_INFORMATION_FAIL,
    Actions.GET_CUSTOMER_INFORMATION_CANCEL,
  )<string, CustomerInformationResponse, Error, undefined>(),
  confirmOrchestrationInSessionStorage: createAction(Actions.CONFIRM_ORCHESTRATION_IN_LOCAL_STORAGE)<string>(),
  clearGenericServerErrorMessage: createAction(Actions.CLEAR_GENERIC_SERVER_ERROR_MESSAGE)(),
};

// Reducers
export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getMyMembership.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.meta.isUpdating = true;
    }),
  )
  .handleAction(actions.getMyMembership.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.meta.isUpdating = false;
      draftState.products.meta.fetchState = 'success';
      draftState.products.ownedProducts = action.payload.customerProducts.ownedProducts.reduce(
        (acc: Record<ProductType['productNumber'], ProductType>, cur) => {
          acc[cur.productNumber] = cur;
          return acc;
        },
        {},
      );
      draftState.products.eligibleProducts = action.payload.customerProducts.eligibleProducts.reduce(
        (acc: Record<ProductType['productNumber'], ProductType>, cur) => {
          acc[cur.productNumber] = cur;
          return acc;
        },
        {},
      );
      draftState.products.householdDetails = action.payload.customerProducts.householdDetails;
      draftState.products.campaignCodeDetails = action.payload.customerProducts.campaignCodeDetails;
      draftState.householdMemberForm = action.payload.householdMemberForm || undefined;
    }),
  )
  .handleAction(actions.getMyMembership.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.meta.isUpdating = false;
      draftState.products.meta.fetchState = 'error';
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getMyMembership.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.products.meta.fetchState = 'initial';
      draftState.meta.isUpdating = false;
    }),
  )
  .handleAction(actions.productActivation.request, (state = initialState, action) =>
    produce(state, (draftState) => {
      action.payload.activationProducts.forEach((product) => {
        draftState.products.eligibleProducts[product.productId].meta = { isUpdating: true };
        delete draftState.errorState;
      });
    }),
  )
  .handleAction(actions.productActivation.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      const { productId } = action.payload.summary.activationProductResponses[0];
      const { orchestrationId } = action.payload;
      draftState.activation = action.payload;
      draftState.products.meta.isUpdating = false;
      draftState.orchestrationIds[orchestrationId] = {
        date: new Date().toISOString(),
        isAddedToSessionStorage: false,
        productId,
        type: OrderActionType.ProductActivation,
      };

      draftState.products.eligibleProducts[productId].meta = {
        isUpdating: false,
        orchestrationId,
      };
    }),
  )
  .handleAction(actions.productActivation.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.products.meta.isUpdating = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.productActivation.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.products.meta.isUpdating = false;
    }),
  )
  .handleAction(actions.productPurchase.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.products.meta.isUpdating = true;
      delete draftState.errorState;
    }),
  )
  .handleAction(actions.productPurchase.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.order = action.payload;
      draftState.products.meta.isUpdating = false;
      draftState.orchestrationIds[action.payload.orchestrationId] = {
        date: new Date().toISOString(),
        isAddedToSessionStorage: false,
        type: OrderActionType.ProductPurchase,
      };
    }),
  )
  .handleAction(actions.productPurchase.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.errorState = action.payload;
      draftState.products.meta.isUpdating = false;
    }),
  )
  .handleAction(actions.productPurchase.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.products.meta.isUpdating = false;
    }),
  )
  .handleAction(actions.productUpdate.request, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.products.ownedProducts[action.payload.productNumber].meta = { isUpdating: true };
      delete draftState.errorState;
    }),
  )
  .handleAction(actions.productUpdate.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.orchestrationIds[action.payload.orchestrationId] = {
        date: new Date().toISOString(),
        isAddedToSessionStorage: false,
        type: OrderActionType.ProductUpdate,
      };
    }),
  )
  .handleAction(actions.productUpdate.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.products.ownedProducts[action.meta.request.productNumber].meta = { isUpdating: false };
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.productUpdate.cancel, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.products.ownedProducts[action.meta.request.productNumber].meta = {
        isUpdating: false,
      };
    }),
  )
  .handleAction(actions.getCustomerInformation.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.customerInformation.meta.fetchState = 'loading';
    }),
  )
  .handleAction(actions.getCustomerInformation.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.customerInformation.meta.fetchState = 'success';
      draftState.customerInformation.data = action.payload;
    }),
  )
  .handleAction(actions.getCustomerInformation.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.customerInformation.meta.fetchState = 'error';
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getCustomerInformation.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.customerInformation.meta.fetchState = 'initial';
    }),
  )
  .handleAction(actions.confirmOrchestrationInSessionStorage, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.orchestrationIds[action.payload].isAddedToSessionStorage = true;
    }),
  )
  .handleAction(actions.clearGenericServerErrorMessage, (state = initialState) =>
    produce(state, (draftState) => {
      delete draftState.errorState;
    }),
  );

// Epics
const purchaseProductEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimMembershipApi, apimContentHub } = state$.value.application;
  const errorArray = [400, 422];

  return action$.pipe(
    ofType(Actions.PURCHASE_PRODUCT),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.productActivation.request>[]
    >,
    mergeMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.meta.token) {
        headers.Authorization = `Bearer ${action.meta.token}`;
      }
      return ajax<PostPaymentOrderResponse>({
        url: `${apimBaseUrl}/${apimMembershipApi}/v2/products/payment`,
        headers,
        method: 'POST',
        body: JSON.stringify(action.payload),
        withCredentials: true,
      }).pipe(
        map(({ response }) => actions.productPurchase.success(response)),
        retryWhen((errors) =>
          errors.pipe(
            mergeMap((error) => {
              if (!errorArray.includes(error.status)) {
                return of(error);
              }
              return throwError(error);
            }),
            // Use concat map to keep the errors in order and make sure they
            // aren't executed in parallel
            concatMap((e, i) =>
              // Executes a conditional Observable depending on the result
              // of the first argument
              iif(
                () => i > 10,
                // If the condition is true we throw the error (the last error)
                throwError(e),
                // Otherwise we pipe this back into our stream and delay the retry
                of(e).pipe(delay(500)),
              ),
            ),
          ),
        ),
        catchError((res) => of(actions.productPurchase.failure(res.response))),
      );
    }),
  );
};

const activateProductEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimMembershipApi, apimContentHub } = state$.value.application;
  const errorArray = [400, 422];

  return action$.pipe(
    ofType(Actions.ACTIVATE_PRODUCT),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.productActivation.request>[]
    >,
    mergeMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.meta.token) {
        headers.Authorization = `Bearer ${action.meta.token}`;
      }
      return ajax<PostActivationOrderResponse>({
        url: `${apimBaseUrl}/${apimMembershipApi}/v2/products/activation`,
        headers,
        method: 'POST',
        body: JSON.stringify(action.payload),
        withCredentials: true,
      }).pipe(
        map(({ response }) => actions.productActivation.success(response)),
        retryWhen((errors) =>
          errors.pipe(
            mergeMap((error) => {
              if (!errorArray.includes(error.status)) {
                return of(error);
              }
              return throwError(error);
            }),
            // Use concat map to keep the errors in order and make sure they
            // aren't executed in parallel
            concatMap((e, i) =>
              // Executes a conditional Observable depending on the result
              // of the first argument
              iif(
                () => i > 10,
                // If the condition is true we throw the error (the last error)
                throwError(e),
                // Otherwise we pipe this back into our stream and delay the retry
                of(e).pipe(delay(500)),
              ),
            ),
          ),
        ),
        catchError((res) => of(actions.productActivation.failure(res.response))),
      );
    }),
  );
};

export const updateProductEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimMembershipApi, apimContentHub } = state$.value.application;
  const errorArray = [400, 422];

  return action$.pipe(
    ofType(Actions.UPDATE_PRODUCT),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.productUpdate.request>[]
    >,
    mergeMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.meta.token) {
        headers.Authorization = `Bearer ${action.meta.token}`;
      }
      return ajax<PostProductUpdateResponse>({
        url: `${apimBaseUrl}/${apimMembershipApi}/v2/products/update`,
        headers,
        method: 'POST',
        body: JSON.stringify(action.payload),
        withCredentials: true,
      }).pipe(
        map(({ response }) => actions.productUpdate.success(response, { request: action.payload })),
        retryWhen((errors) =>
          errors.pipe(
            mergeMap((error) => {
              if (!errorArray.includes(error.status)) {
                return of(error);
              }
              return throwError(error);
            }),
            // Use concat map to keep the errors in order and make sure they
            // aren't executed in parallel
            concatMap((e, i) =>
              // Executes a conditional Observable depending on the result
              // of the first argument
              iif(
                () => i > 10,
                // If the condition is true we throw the error (the last error)
                throwError(e),
                // Otherwise we pipe this back into our stream and delay the retry
                of(e).pipe(delay(500)),
              ),
            ),
          ),
        ),
        catchError((res) => of(actions.productUpdate.failure(res.response, { request: action.payload }))),
      );
    }),
  );
};

export const getMyMembershipEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_MY_MEMBERSHIP),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getMyMembership.request>[]
    >,
    switchMap(([action]) => {
      let url = `${apimBaseUrl}/${apimMembershipApi}/v2/products?mittNaf=true`;
      if (action.meta.campaignCode) {
        url = `${url}&campaignCode=${action.meta.campaignCode}`;
      }
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.payload) {
        headers.Authorization = `Bearer ${action.payload}`;
      }
      return ajax<GetProductsResponse>({
        url,
        headers,
      }).pipe(
        map(({ response }) => actions.getMyMembership.success(response)),
        catchError(() =>
          of(
            actions.getMyMembership.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente medlemskapet! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
            ),
          ),
        ),
      );
    }),
  );
};

export const getCustomerInformationEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimContentHub, apimCustomerLogicApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_CUSTOMER_INFORMATION),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getCustomerInformation.request>[]
    >,
    switchMap(([action]) => {
      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
      };
      if (action.payload) {
        headers.Authorization = `Bearer ${action.payload}`;
      }
      return ajax<CustomerInformationResponse>({
        url: `${apimCustomerLogicApi}/customer/product-information`,
        headers,
      }).pipe(
        map(({ response }) => actions.getCustomerInformation.success(response)),
        catchError(() =>
          of(
            actions.getCustomerInformation.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente kundeinformasjon! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
            ),
          ),
        ),
      );
    }),
  );
};

export const epics: TypedEpic[] = [
  getMyMembershipEpic,
  purchaseProductEpic,
  activateProductEpic,
  updateProductEpic,
  getCustomerInformationEpic,
];
