import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Accordion } from '@naf/accordion';
import { Table, TableBody, TableRow, TableCell } from '@naf/table';
import { breakpoints } from '@naf/theme';
import { mapUnitInData as UMapper, numberWithSpaces } from '../../../utils';
import { terms as T, termsMapper as TMapper } from '../../../utils/bilguide';
import { LINK_COLOR, LINK_HOVER_COLOR } from '../../../styles/colors';
import { omitNestedKeys } from './utils';

interface Props {
  data: any;
}

const deprecatedFields = [
  'chargingCable',
  'connectionType',
  'fastCharger',
  'numberofGears',
  'numberofCollisionBag',
  'maxTowWeight_kg',
  'trailerPrice',
  'roofPayload_kg',
  '',
];

const renderDataRow = (d: any[]) => {
  const term = d[0];
  const value = d[1];

  // Remove fields based on NOA-1553, charging data is removed due to unreliable data
  if (deprecatedFields.includes(term) || deprecatedFields.includes(TMapper(term))) return null;

  let unit = '';
  if (!!value && value !== 'tbi' && term.includes('_')) {
    unit = ` ${UMapper(term)}`;
  }
  if (TMapper(term) === 'Pris' || term === 'trailerPrice') {
    unit = ',-';
  }
  return (
    !!value &&
    value !== 'tbi' && (
      <TableRow key={`${value}-fragment-${term}}`}>
        <TableCell as="th">{TMapper(term)}</TableCell>
        <StyledTableCell as="td">
          {typeof value !== 'number' ? value : `${numberWithSpaces(value)}${unit}`}
        </StyledTableCell>
      </TableRow>
    )
  );
};

export interface VehicleSizeData {
  allowedTotalWeight_kg?: number;
  axelDistance_mm?: number;
  groundClearance_mm?: number;
  height_mm?: number;
  length_mm?: number;
  maxCargospaceVolume_l?: number;
  maxTowWeight_kg?: number;
  cargospaceVolume_l?: number;
  numberofDoors?: number;
  numberofSeats?: number;
  payload_kg?: number;
  rimDimensionFront?: string;
  rimDimensionRear?: string;
  roofPayload_kg?: number;
  maxRoofPayload_kg?: number;
  trailerHitch?: string;
  trailerPrice?: number;
  weight_kg?: number;
  wheelDimensionFront?: string;
  wheelDimensionRear?: string;
  width_mm?: number;
}

// ? Need to be represented with table component from design system
const CarVariantData = ({ data }: Props) => {
  const { vehicleVariantSpecification } = omitNestedKeys(data, deprecatedFields);

  const generalData = vehicleVariantSpecification[T.GENERAL];
  const electricEmissionData = vehicleVariantSpecification[T.ELECTRIC_EMISSION_DETAILS];
  const [sizesData, setSizesData] = useState(vehicleVariantSpecification[T.SIZE_AND_WEIGHT] as VehicleSizeData);
  const equipmentData = vehicleVariantSpecification[T.EQUIPMENT];

  // Necessary to provide maximum roof payload from "wrong" data source
  useEffect(() => {
    if (sizesData && !sizesData.maxRoofPayload_kg && data?.vehicleDimensions?.maxRoofPayload_kg) {
      // eslint-disable-next-line camelcase
      setSizesData({ ...sizesData, maxRoofPayload_kg: data?.vehicleDimensions?.maxRoofPayload_kg });
    }
  }, [data, sizesData, setSizesData]);

  useMemo(() => {
    if (data?.exceptions) {
      data.exceptions.forEach((exception: string) => deprecatedFields.push(exception));
    }
  }, [data]);

  return (
    <div>
      {generalData && Object.keys(generalData).length > 0 && (
        <Accordion label={TMapper(T.GENERAL)} size="large">
          <Table variant="vertical" minWidth="320">
            <TableBody>{Object.entries(generalData).map((d: any[]) => renderDataRow(d))}</TableBody>
          </Table>
        </Accordion>
      )}
      {electricEmissionData && Object.keys(electricEmissionData).length > 0 && (
        <Accordion label={TMapper(T.ELECTRIC_EMISSION_DETAILS)} size="large">
          <Table variant="vertical" minWidth="320">
            <TableBody>{Object.entries(electricEmissionData).map((d: any[]) => renderDataRow(d))}</TableBody>
          </Table>
        </Accordion>
      )}
      {sizesData && Object.keys(sizesData).length > 0 && (
        <Accordion label={TMapper(T.SIZE_AND_WEIGHT)} size="large">
          <Table variant="vertical" minWidth="320">
            <TableBody>{Object.entries(sizesData).map((d: any[]) => renderDataRow(d))}</TableBody>
          </Table>
        </Accordion>
      )}
      {equipmentData && Object.keys(equipmentData).length > 0 && (
        <Accordion label={TMapper(T.EQUIPMENT)} size="large">
          <Table variant="vertical" minWidth="320">
            <TableBody>{Object.entries(equipmentData).map((d: any[]) => renderDataRow(d))}</TableBody>
          </Table>
        </Accordion>
      )}

      <Credit>
        Opplysningene er hentet og gjengitt med samtykke fra Opplysningsrådet for veitrafikken AS,&nbsp;
        <StyledTextLink href="https://ofv.no/" target="_blank" rel="noreferrer">
          ofv.no
        </StyledTextLink>
      </Credit>
    </div>
  );
};

const StyledTableCell = styled(TableCell)`
  @media (max-width: ${breakpoints.s}) {
    padding-left: 10px;
  }
`;

const Credit = styled.p`
  font-size: 1rem;
  line-height: 1.5;
`;

const StyledTextLink = styled.a`
  cursor: pointer;
  color: ${LINK_COLOR};
  text-decoration: underline;
  &:hover {
    color: ${LINK_HOVER_COLOR};
    text-decoration: none;
  }
`;

export default CarVariantData;
