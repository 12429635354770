import { fill } from '@cloudinary/url-gen/actions/resize';
import React from 'react';
import { AdvancedImage, lazyload, placeholder, responsive } from '@cloudinary/react';
import styled from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { animation, spacing, themeLight } from '@naf/theme';
import { Link } from 'react-router-dom';
import { SimpleArticle } from '../../../../../types/SimpleArticle';
import { useCloudinary } from '../../../hooks/Cloudinary/useCloudinary';
import { useDocumentUrlWithNode } from '../../../hooks/useDocumentUrl';
import { HoverWrap } from '../blocks/LatestArticleBlock/LatestArticleItem';

type Props = {
  block: SimpleArticle;
};

export const VerticalArticleBlock = ({ block }: Props) => {
  const { nafnoArticle, ar } = block;
  const { image, name, ingress } = nafnoArticle;

  const contentUrl = useDocumentUrlWithNode(nafnoArticle) || '';

  const cld = useCloudinary();

  const cldImage =
    (image &&
      image.publicId &&
      cld.image(image.publicId).resize(fill().aspectRatio(ar)).quality('auto:good').format('auto')) ||
    undefined;

  return (
    <ArticleCard to={contentUrl}>
      {cldImage ? (
        <ImageContainer $ratio={ar.split(':').join(' / ')}>
          <AdvancedImage
            style={{ maxWidth: '100%' }}
            alt={image?.altText || image?.alt}
            cldImg={cldImage}
            plugins={[
              lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }),
              responsive({ steps: 200 }),
              placeholder({ mode: 'blur' }),
            ]}
          />
        </ImageContainer>
      ) : null}

      <StyledHeader variant={TextVariant.CardHeader}>
        <HoverWrap>{name}</HoverWrap>
      </StyledHeader>
      <StyledDescription>{ingress}</StyledDescription>
    </ArticleCard>
  );
};

const StyledHeader = styled(Text)`
  margin: ${spacing.space24} 0 ${spacing.space12};
`;

const StyledDescription = styled(Text)`
  margin: 0;
`;

const ImageContainer = styled.div<{ $ratio: string }>`
  min-width: 100%;
  overflow: hidden;
  ${({ $ratio }) => ($ratio ? `aspect-ratio: ${$ratio};` : '')}

  img {
    transition: transform 0.6s ${animation.easeIn};
  }
`;

export const ArticleCard = styled(Link)`
  display: block;
  box-sizing: border-box;
  flex-shrink: 0;
  position: relative;
  text-decoration: none;
  color: ${({ theme }: { theme?: typeof themeLight }) =>
    theme?.typography.defaultText || themeLight.typography.defaultText} !important;

  &:hover {
    ${HoverWrap} {
      background-size: 100% 2px;
    }

    ${ImageContainer} img {
      transform: scale(105%);
    }
  }
`;
