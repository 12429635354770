import React from 'react';
import { Button, ButtonVariant } from '@naf/button';
import { useHistory } from 'react-router';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import { RelatedReferenceComponentType } from '../../../../../../../types/articleType';
import { useDocumentUrlWithNode } from '../../../../../hooks/useDocumentUrl';
import { useTrackCTA } from '../../../../../utils/Tracking/CtaClick/TrackCtaClick';

interface CtaButtonInternalLinkSerializerProps {
  value: {
    text: string;
    anchorId?: string;
    referenceComponent: RelatedReferenceComponentType;
    variant?: ButtonVariant;
  };
}

const CtaButtonInternalLink = ({
  value: { text, anchorId, referenceComponent, variant },
}: CtaButtonInternalLinkSerializerProps) => {
  const history = useHistory();
  const referenceUrl = useDocumentUrlWithNode(referenceComponent) || '';
  const trackCTA = useTrackCTA();

  let linkUrl = referenceUrl;
  if (anchorId) {
    linkUrl = `${referenceUrl}#${anchorId}`;
  }

  return (
    <Button
      onClick={(e: any) => {
        e.preventDefault();
        trackCTA({ cta_position: 'content', text });
        history.push(linkUrl);
      }}
      variant={variant}
    >
      {text}
    </Button>
  );
};

export const CtaButtonInternalLinkSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: CtaButtonInternalLinkSerializerProps) => (
      <ArticleBlockContentGridCol className="CtaButton">
        <CtaButtonInternalLink value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: CtaButtonInternalLinkSerializerProps) => <CtaButtonInternalLink value={value} />;
};
