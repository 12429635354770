import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { Button } from '@naf/button';
import { ExpandLess } from '@styled-icons/material-outlined/ExpandLess';
import { ExpandMore } from '@styled-icons/material-outlined/ExpandMore';
import useSelector from '../../redux/typedHooks';
import { RelatedModel } from './RelatedModel';
import { RelatedModelSkeleton } from './RelatedModelSkeleton';

type Props = {
  currentSlug: string;
  inSidebar?: boolean;
};

function getPreviousTwoAndNextSevenModels(modelSlugs: string[], startIndex: number): string[] {
  const result: string[] = [];
  const { length } = modelSlugs;

  for (let i = startIndex - 2; i < startIndex + 8; i += 1) {
    // Use modulo to wrap around to the end or beginning if necessary
    const index = ((i % length) + length) % length;
    if (result.indexOf(modelSlugs[index]) === -1) {
      result.push(modelSlugs[index]);
    }
  }

  return result;
}

export const RelatedModels: React.FC<Props> = ({ currentSlug, inSidebar }) => {
  const { data, mappedData, isUpdating } = useSelector((state) => state.cars);

  const [showAll, setShowAll] = useState(false);
  const modelSlugs = data[0];
  const testedModelSlugs = modelSlugs.filter((slug) => mappedData[slug].data.testedByElprix);

  if (isUpdating) {
    return (
      <Container $inSidebar={inSidebar} $showAll={inSidebar ? true : showAll}>
        <Header variant={TextVariant.Header3}>Lignende modeller</Header>
        {Array.from(Array(11).keys()).map((key) => (
          <RelatedModelSkeleton key={key} />
        ))}
      </Container>
    );
  }

  if (testedModelSlugs.length <= 1) return null;

  const currentCarPosition = testedModelSlugs.findIndex((slug) => slug === currentSlug);

  return (
    <>
      <Container $inSidebar={inSidebar} $showAll={inSidebar ? true : showAll}>
        <Header variant={TextVariant.Header3}>Lignende modeller</Header>
        {getPreviousTwoAndNextSevenModels(testedModelSlugs, currentCarPosition).map((slug) => (
          <RelatedModel
            variant="list-item"
            key={slug}
            model={mappedData[slug].data}
            isCurrent={currentSlug === slug}
            inSidebar={inSidebar}
          />
        ))}
        {!showAll && <Overlay />}
      </Container>
      <ButtonContainer $inSidebar={inSidebar}>
        <StyledButton type="button" variant="secondary" onClick={() => setShowAll(!showAll)}>
          {showAll ? (
            <>
              <ExpandLess height={16} width={16} />
              <Text>Vis færre</Text>
            </>
          ) : (
            <>
              <ExpandMore height={16} width={16} />
              <Text>Vis flere</Text>
            </>
          )}
        </StyledButton>
      </ButtonContainer>
    </>
  );
};

const Container = styled.div<{ $inSidebar?: boolean; $showAll?: boolean }>`
  display: ${({ $inSidebar }) => ($inSidebar ? 'flex' : 'none')};
  flex-direction: column;
  margin-top: ${({ $inSidebar }) => ($inSidebar ? spacing.space80 : spacing.space48)};
  transition: height 0.3s;
  @media (max-width: ${breakpoints.l}) {
    display: ${({ $inSidebar }) => (!$inSidebar ? 'flex' : 'none')};
    ${({ $inSidebar, $showAll }) =>
      !$inSidebar &&
      css`
        overflow-y: hidden;
        margin-left: -24px;
        width: 100vw;
        height: ${$showAll ? '752px' : '396px'};
      `};
  }
`;

const ButtonContainer = styled.div<{ $inSidebar: boolean }>`
  position: absolute;
  bottom: -18px;
  flex-direction: column;
  z-index: 1;
  align-items: center;
  width: calc(100vw);
  margin-left: -24px;
  display: none;

  @media (max-width: ${breakpoints.l}) {
    display: ${({ $inSidebar }) => ($inSidebar ? 'none' : 'flex')};
  }
`;

const Overlay = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  height: 52px;
  width: 100vw;

  @media (max-width: ${breakpoints.l}) {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  padding: ${spacing.space4} ${spacing.space16} ${spacing.space4} ${spacing.space12};
  height: fit-content;
  border-radius: 50px !important;
  cursor: pointer;

  p {
    margin: 0;
  }

  svg {
    margin-right: ${spacing.space8};
  }
`;

const Header = styled(Text)`
  text-align: left;
  margin: 0 0 ${spacing.space16} ${spacing.space24};
`;
