import React, { useEffect, useMemo } from 'react';
import { useCookieBanner } from '@naf/cookie-banner';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { cookieDeclarationPath, customizeCookiesPath } from '../ThemedApp';
import { trackCookieBannerClick } from '../../utils/Tracking/TrackCookies';

export type CookiebotInstance = Window & { Cookiebot: any };

export const CustomCookieBanner = () => {
  const { showCookieBanner, CookieBanner, closeCookieBanner } = useCookieBanner();
  const history = useHistory();

  // Show cookie banner when Cookiebot initializes dialog prompt
  const setCookieBannerState = useMemo(
    () => () => {
      if (window && (window as unknown as CookiebotInstance).Cookiebot) {
        const { Cookiebot } = window as unknown as CookiebotInstance;
        // Never open cookie banner when user is customizing cookie consents
        if (window.location.pathname !== customizeCookiesPath) {
          showCookieBanner(Cookiebot);
        } else {
          Cookiebot.hide();
          closeCookieBanner();
        }
      } else {
        closeCookieBanner();
      }
    },
    [closeCookieBanner, showCookieBanner],
  );

  useEffect(() => {
    window.addEventListener('CookiebotOnDialogInit', setCookieBannerState);

    return () => window.removeEventListener('CookiebotOnDialogInit', setCookieBannerState);
  }, [setCookieBannerState]);

  const customizeCookiesRedirect = () => {
    history.push(customizeCookiesPath);
  };

  return (
    <CookieBanner
      data-elastic-exclude
      data-nosnippet
      submitButton={{
        text: 'Tillat alle',
        onClick: () => {
          trackCookieBannerClick('Tillat alle', 'preferences:true|statistics:true|marketing:true');
        },
      }}
      selectionButton={{
        text: 'Tillat kun nødvendige',
        preferences: false,
        statistics: false,
        marketing: false,
        onClick: () => {
          trackCookieBannerClick('Tillat kun nødvendige, "preferences:false|statistics:false|marketing:false"');
        },
      }}
      customizeButton={{
        text: 'Tilpass innstillinger',
        redirect: customizeCookiesRedirect,
        onClick: () => {
          trackCookieBannerClick('Tilpass innstillinger');
        },
      }}
    >
      NAF.no benytter informasjonskapsler (cookies) slik at vi kan yte deg bedre service. De blir hovedsakelig benyttet
      for trafikkmåling og optimalisering av tjenesten. <Link to={cookieDeclarationPath}>Les mer her</Link> om hvordan
      NAF behandler informasjonskapsler.
    </CookieBanner>
  );
};
