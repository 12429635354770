/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import { useWindowResize } from '../../../hooks/Window/useWindowResize';

export const HighChartComponent = (props: any) => {
  const [Highcharts, setHighcharts] = useState<any>();
  const [HighchartsReact, setHighchartsReact] = useState<any>();

  useEffect(() => {
    const initHighcharts = async () => {
      const dynamicallyImportedHighcharts = await import('highcharts');
      const { default: dynamicallyImportedHighchartsMore } = await import('highcharts/highcharts-more');
      const { default: dynamicallyImportedHighchartsA11y } = await import('highcharts/modules/accessibility');
      const { default: dynamicallyImportedHighchartsDumbbell } = await import('highcharts/modules/dumbbell');

      dynamicallyImportedHighchartsMore(dynamicallyImportedHighcharts);
      dynamicallyImportedHighchartsA11y(dynamicallyImportedHighcharts);
      dynamicallyImportedHighchartsDumbbell(dynamicallyImportedHighcharts);
      setHighcharts(dynamicallyImportedHighcharts);

      const { HighchartsReact: dynamicallyImportedHighchartsReact } = await import('highcharts-react-official');
      setHighchartsReact(dynamicallyImportedHighchartsReact);
    };
    initHighcharts();
  }, []);

  const { options } = props;
  const [isRendering, setIsRendering] = useState(false);

  useWindowResize(() => Highcharts && Highcharts.charts.forEach((chart: any) => chart && chart.reflow()));

  // eslint-disable //
  if (Highcharts && HighchartsReact) {
    return (
      <Waypoint onEnter={() => setIsRendering(true)}>
        <Container role="region" aria-label="diagram">
          {(isRendering && <HighchartsReact highcharts={Highcharts} options={options} {...props} />) || null}
        </Container>
      </Waypoint>
    );
  }
  return null;
};

const Container = styled.div`
  > .highcharts-container {
    width: 100% !important;
    height: 100% !important;
  }
`;
