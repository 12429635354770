import { AdvancedImage, placeholder, responsive } from '@cloudinary/react';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { Text, TextVariant } from '@naf/text';
import { animation, breakpoints, spacing, themeLight } from '@naf/theme';
import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ArticleReference } from '../../../../../types/articleReferenceType';
import { MultiRowArticleBlockType } from '../../../../../types/multiRowArticleBlockType';
import { useCloudinary } from '../../../hooks/Cloudinary/useCloudinary';
import { useDevicePixelRatio } from '../../../hooks/useDevicePixelRatio';
import { useDocumentUrlWithNode } from '../../../hooks/useDocumentUrl';
import { LinkField } from './LatestArticleBlock';

type MultiRowArticleProps = {
  block: MultiRowArticleBlockType;
};

const MultiRowArticleBlock = ({ block }: MultiRowArticleProps) => (
  <div>
    <BlockHeader>
      <Text variant={TextVariant.Header2}>{block.title}</Text>
      <LinkField link={block.cta.link} />
    </BlockHeader>

    <ArticleContainer>
      {block.rows?.map((row, i) =>
        row.news === null ? null : (
          // This array will never be reordered between renders, so indices are fine.
          // eslint-disable-next-line react/no-array-index-key
          <ArticleRow key={i} $rowLength={row.news.length}>
            {row.news.map((article) => (
              <Article key={article.name} article={article} $rowLength={row.news.length} />
            ))}
          </ArticleRow>
        ),
      )}
    </ArticleContainer>
  </div>
);

type ArticleProps = {
  article: ArticleReference;
  $rowLength: number;
};

const Article = ({ article, $rowLength }: ArticleProps) => {
  const contentUrl = useDocumentUrlWithNode(article) || '';

  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();
  const cldImg = article.image?.publicId
    ? cld
        .image(article.image.publicId)
        .resize(fill().width(1200).height(720))
        .delivery(dpr(devicePixelRatio))
        .quality('auto')
        .format('auto')
    : undefined;

  return (
    <ArticleCard to={contentUrl}>
      <ImageContainer>
        <CardImage
          alt={article.image?.altText}
          cldImg={cldImg}
          plugins={[responsive({ steps: 200 }), placeholder({ mode: 'blur' })]}
        />
      </ImageContainer>
      <CardContent $rowLength={$rowLength}>
        <CardHeader variant={TextVariant.Header2} $rowLength={$rowLength}>
          <HoverWrap>{article.name}</HoverWrap>
        </CardHeader>

        <CardBody variant={TextVariant.BodyText}>{article.ingress.substring(0, 80).trimEnd()}...</CardBody>
      </CardContent>
    </ArticleCard>
  );
};

export const BlockHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;

  margin-bottom: ${spacing.space24};

  > h2 {
    margin: 0;
  }
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: ${spacing.space24};
`;

export const HoverWrap = styled.span<{ theme?: typeof themeLight }>`
  background-size: 0 2px;
  background-position: 0 100%;
  background-image: linear-gradient(
    90deg,
    ${({ theme }) =>
      theme.componentColors
        ? `
        ${theme.componentColors.interactiveElement.active},
        ${theme.componentColors.interactiveElement.active}`
        : `
        ${themeLight.componentColors.interactiveElement.active},
        ${themeLight.componentColors.interactiveElement.active}`}
  );
  background-repeat: no-repeat;
  transition: background-size 0.3s ease;
  text-decoration: none;
  display: inline;
`;

// When an ArticleCard is alone on its row, give the text some special styling
// to overlap image. This is true for all cards below breakpoints.l, otherwise
// only for cards on a row of length 1.
const CardContent = styled.div<{ $rowLength: number; theme: typeof themeLight }>`
  display: flex;
  flex-flow: column nowrap;
  row-gap: ${spacing.space12};
  position: relative;

  color: ${({ theme }) => theme?.typography?.defaultText || themeLight.typography.defaultText};

  @media (min-width: calc(${breakpoints.l} + 1px)) {
    ${({ $rowLength }) =>
      $rowLength === 1
        ? css`
            margin: -${spacing.space80} ${spacing.space40} 0 ${spacing.space40};
            padding: ${spacing.space40};
            border-radius: 4px;
            background-color: ${({ theme }) => theme?.background?.default || themeLight.background.default};
            position: relative;
          `
        : `margin-top: ${spacing.space24};`}
  }

  @media (max-width: ${breakpoints.l}) {
    margin: -${spacing.space80} ${spacing.space40} 0 ${spacing.space40};
    padding: ${spacing.space40};
    border-radius: 4px;
    background-color: ${({ theme }) => theme?.background?.default || themeLight.background.default};
    position: relative;
  }

  @media (max-width: ${breakpoints.s}) {
    margin: -${spacing.space80} ${spacing.space8} 0 ${spacing.space8};
    padding: ${spacing.space16};
    border-radius: 4px;
    background-color: ${({ theme }) => theme?.background?.default || themeLight.background.default};
    position: relative;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;

  border-radius: 2px;
  overflow: hidden;
`;

const CardImage = styled(AdvancedImage)`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;

  transition: transform 0.6s ${animation.easeIn};
`;

const ArticleCard = styled(Link)`
  display: block;
  box-sizing: border-box;
  flex-shrink: 0;
  position: relative;

  text-decoration: none;

  &:hover {
    ${HoverWrap} {
      background-size: 100% 2px;
    }

    ${CardImage} {
      transform: scale(105%);
    }
  }
`;

const ArticleRow = styled.div<{ $rowLength: number }>`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  column-gap: ${spacing.space32};

  ${ArticleCard} {
    --row-length: ${({ $rowLength }) => $rowLength};
    width: calc((100% - (var(--row-length) - 1) * ${spacing.space32}) / var(--row-length));
  }

  @media (max-width: ${breakpoints.l}) {
    flex-flow: column nowrap;
    row-gap: ${spacing.space24};

    ${ArticleCard} {
      width: 100%;
    }
  }

  ${({ $rowLength }) =>
    $rowLength < 3
      ? css`
          @media (max-width: ${breakpoints.s}) {
            ${ArticleCard} {
              margin: 0 -${spacing.space24};
              width: calc(100% + 2 * ${spacing.space24});
            }

            ${CardImage} {
              aspect-ratio: 3 / 4;
            }
          }
        `
      : ''}
`;

const CardHeader = styled(Text)<{ $rowLength: number }>`
  margin: 0;

  ${({ $rowLength }) =>
    $rowLength === 1
      ? css`
          // Header2 if alone on row
          font-size: 1.75rem;
          line-height: 2.25rem;
          font-weight: black;
        `
      : ''};

  @media (max-width: ${breakpoints.s}) {
    // Mobile/Header2 on small screens
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: black;
  }
`;

const CardBody = styled(Text)`
  margin: 0;
`;

export { MultiRowArticleBlock };
