/* eslint-disable camelcase */
import { useLocation } from 'react-router-dom';
import { useGTMDataLayer } from '../../../hooks/GTM/useGTMDataLayer';
import useCloseMenu from '../../../hooks/useCloseMenu';
import useEspial from '../../../hooks/Espial/useEspial';

export const useHandleBecomeMemberClick = () => {
  const dataLayer = useGTMDataLayer();
  const location = useLocation();
  const closeMenu = useCloseMenu();
  const { trackMenuClick } = useEspial();

  return () => {
    trackMenuClick([
      { menu_section_name: 'Topbar' },
      { menu_item_name: 'Bli medlem' },
      { menu_item_url: '/medlemskap' },
    ]);

    dataLayer?.push({
      event: 'cta_click',
      menuPage: location.pathname,
      menuItemName: 'Bli medlem',
      menuItemUrl: '/medlemskap',
      cta_position: 'topbar',
    });
    closeMenu();
  };
};

export const useHandleBookAppointmentClick = () => {
  const dataLayer = useGTMDataLayer();
  const location = useLocation();
  const closeMenu = useCloseMenu();
  const { trackMenuClick } = useEspial();

  return () => {
    trackMenuClick([
      { menu_section_name: 'Topbar' },
      { menu_item_name: 'Bestill time' },
      { menu_item_url: '/bilverksted-og-tester' },
    ]);

    dataLayer?.push({
      event: 'cta_click',
      menuPage: location.pathname,
      menuItemName: 'Bestill time',
      menuItemUrl: '/bilverksted-og-tester',
      cta_position: 'topbar',
    });
    closeMenu();
  };
};

export const useHandleNavigationClick = ({
  event,
  menuSectionName,
  menuItemUrl,
  menuItemName,
  menuPage,
  onClickAction,
}: {
  event?: string;
  menuSectionName?: string;
  menuItemUrl?: string;
  menuItemName?: string;
  menuPage?: string;
  onClickAction?: () => void;
}) => {
  const dataLayer = useGTMDataLayer();
  const closeMenu = useCloseMenu();
  const { trackMenuClick } = useEspial();

  return () => {
    trackMenuClick([
      { menu_section_name: menuSectionName },
      { menu_item_name: menuItemName },
      { menu_item_url: menuItemUrl },
    ]);

    const data = {
      event,
      menuSectionName,
      menuItemUrl,
      menuItemName,
      menuPage,
    };
    dataLayer?.push(data);
    closeMenu();

    if (onClickAction) {
      onClickAction();
    }
  };
};
