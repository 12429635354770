import React, { useState } from 'react';
import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';
import { TextVariant } from '@naf/text';
import { nafColor } from '@nafcore/theme';
import { CarMeta, ChargeTest, RangeTest } from '../../../../types/carModelType';
import { CombinedResultCard } from '../../components/SectionBlocks/Testshowcase/CombinedResultCard';
import { Caption, ContentHeader } from './RangeTests';
import { ChargeCurve, Legend } from '../../components/bilguide/chart/ChargeCurve';
import { plotLineStyle } from '../../components/SectionBlocks/Testshowcase/ChargeCurveWrapper';
import BlockContent from '../../components/block-content/BlockContent';
import { VehicleVariantRadio } from './VehicleVariantRadio';
import useSelector from '../../redux/typedHooks';
import { formattedPrice } from '../../lib/formattedPrice';
import { DataTable } from './DataTable';
import { BlockContentWrapper } from './BlockContentWrapper';

export type TestData = ChargeTest & Pick<RangeTest, 'statedRangeKm' | 'statedConsumptionKWhper100km'>;

export const ChargeTestContent: React.FC<{
  tests: { [key: string]: TestData };
  sanityImages: CarMeta['cloudinaryImages'];
  slug: string;
}> = ({ tests, sanityImages, slug }) => {
  const [selectedVariantId, setSelected] = useState<string>(Object.keys(tests)[0]);

  const variant = useSelector((state) =>
    state.cars.mappedData[slug].data.vehicleVariants.find(({ id }) => id === selectedVariantId),
  );

  const {
    context,
    vehicleModelReference,
    readings,
    averageChargeEfficiency,
    chargeTestResultSummary,
    chargeTestSummary,
    statedRangeKm,
    statedConsumptionKWhper100km,
    batteryCapacity,
    statedTimeTo80PercentMinutes,
    maxChargeEfficiency,
  } = tests[selectedVariantId];

  const { season, testDate } = context;

  const flyoutEntries = [
    {
      label: 'Pris for testbil',
      value: formattedPrice(vehicleModelReference.price ?? variant.priceNOK),
      unit: 'kr',
    },
    { label: 'Chassis', value: variant.chassis },
    {
      label: 'Rekkevidde (WLTP)',
      value: statedRangeKm ?? variant.electricVehicleDetails.rangeWLTP_km,
      unit: 'km',
      excludeField: 'rangeWLTP_km',
    },
    {
      label: 'Forbruk / 100 km (WLTP)',
      value: statedConsumptionKWhper100km ?? variant.electricVehicleDetails.electricConsumptionWLTP_kWhper100km,
      unit: 'kWh',
      excludeField: 'electricConsumptionWLTP_kWhper100km',
    },
    {
      label: 'Netto batteripakke',
      value: batteryCapacity ?? variant.batteryDetails?.capacity_kWh,
      unit: 'kWh',
    },
    {
      label: 'Maksimal ladeeffekt',
      value: maxChargeEfficiency,
      unit: 'kW',
    },
  ];

  return (
    <>
      {Object.keys(tests).length > 1 && (
        <VehicleVariantRadio
          sanityImages={sanityImages}
          name={`chargeTest_${season}_${testDate}`}
          tests={Object.values(tests)}
          selected={selectedVariantId}
          setSelected={setSelected}
        />
      )}
      <BlockContentWrapper>
        <BlockContent value={chargeTestSummary} />
      </BlockContentWrapper>
      {(statedTimeTo80PercentMinutes || maxChargeEfficiency) && (
        <CombinedResultCard
          variant="result"
          context={{
            season,
            testDate,
            tests: { range: false, consumption: false, chargeTime: true, maxEffect: true },
          }}
          data={{
            testData: {
              chargeTest: tests[selectedVariantId],
              image: sanityImages.find(
                (img) => img?.variant?.id && img.variant.id === vehicleModelReference?.vehicleId,
              ),
              rangeTest: null,
            },
            ...vehicleModelReference,
          }}
        />
      )}
      {Object.keys(readings ?? {}).length > 0 && (
        <>
          <ChargeCurveContainer>
            <ChargeCurve
              summerData={season === 'summer' ? tests[selectedVariantId] : undefined}
              winterData={season === 'winter' ? tests[selectedVariantId] : undefined}
              asBlock
              plotLines={[
                {
                  label: {
                    align: 'right',
                    text: `GJ.SNITT: ${Math.round(averageChargeEfficiency)} kW`,
                    x: -5,
                    style: {
                      color: season === 'summer' ? nafColor.primary.park : nafColor.information.information,
                      fontSize: 'bold,',
                    },
                  },
                  zIndex: 2,
                  width: 2,
                  value: averageChargeEfficiency,
                  dashStyle: 'Dash',
                  color: season === 'summer' ? nafColor.primary.park : nafColor.information.information,
                },
                {
                  ...plotLineStyle,
                  label: {
                    align: 'left',
                    text: 'Hurtiglading'.toUpperCase(),
                  },
                  value: 50,
                },
                {
                  ...plotLineStyle,
                  label: {
                    align: 'left',
                    text: 'Lynlading'.toUpperCase(),
                  },
                  value: 150,
                },
                {
                  ...plotLineStyle,
                  label: {
                    align: 'left',
                    text: 'Normallading'.toUpperCase(),
                  },
                  className: 'normal-charging',
                  value: 0,
                },
              ]}
            />
          </ChargeCurveContainer>
          <Caption>
            Grafen viser bilens ladekurve fra 10&ndash;80% oppladet batteri
            {averageChargeEfficiency ? ', og gjennomsnittlig ladeeffekt.' : '.'}
          </Caption>
        </>
      )}
      {chargeTestResultSummary?.length ? (
        <>
          <ContentHeader variant={TextVariant.Header2}>Testresultater</ContentHeader>
          <BlockContentWrapper>
            <BlockContent value={chargeTestResultSummary} />
          </BlockContentWrapper>
        </>
      ) : null}
      {variant && (
        <DataTable
          tableTitle="Om testbilen"
          entries={flyoutEntries}
          flyoutData={{
            ...(variant || {}),
            vehicleVariantSpecification: {
              ...variant?.vehicleVariantSpecification,
              general: {
                ...variant?.vehicleVariantSpecification.general,
                priceNOK: vehicleModelReference.price || variant?.priceNOK,
              },
              electricAndEmissionDetails: {
                ...variant?.vehicleVariantSpecification?.electricAndEmissionDetails,
                rangeWLTP_km: statedRangeKm || variant?.electricVehicleDetails.rangeWLTP_km,
                electricConsumptionWLTP_kWhper100km:
                  statedConsumptionKWhper100km || variant?.electricVehicleDetails.electricConsumptionWLTP_kWhper100km,
              },
            },
            exceptions: flyoutEntries.filter((f) => f.value).map(({ excludeField, label }) => excludeField ?? label),
          }}
        />
      )}
    </>
  );
};

const ChargeCurveContainer = styled.div`
  width: 100%;

  @media (max-width: ${breakpoints.s}) {
    margin: 0 -${spacing.space24};
    width: calc(100% + 2 * ${spacing.space24});

    ${Legend} {
      margin-left: ${spacing.space24};
    }
  }
`;
