import React, { ReactElement } from 'react';
import { ElPrix } from './Designs/ElPrix/ElPrix';
import { Christmas } from './Designs/Christmas/Christmas';
import useSelector from '../../../redux/typedHooks';
import { Easter } from './Designs/Easter/Easter';

export const Decorations = ({ isScrolled }: { isScrolled?: boolean }) => {
  const decorationToShow = useSelector((state) => state.globalSettings.settings?.decoration);

  let decorationComponent: ReactElement | null;
  switch (decorationToShow) {
    case 'elprix':
      decorationComponent = <ElPrix isScrolled={isScrolled} />;
      break;
    case 'christmas':
      decorationComponent = <Christmas isScrolled={isScrolled} />;
      break;
    case 'easter':
      decorationComponent = <Easter isScrolled={isScrolled} />;
      break;
    case 'none':
    default:
      decorationComponent = null;
      break;
  }

  return decorationComponent;
};
