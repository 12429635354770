import React from 'react';
import { ButtonVariant } from '@naf/button';
import { useHistory, useLocation } from 'react-router-dom';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import { useGTMDataLayer } from '../../hooks/GTM/useGTMDataLayer';
import * as S from './Styles';
import { ButtonType, ModularExternalLinkType } from '../../../../types/ModularButtonBlockType';

interface ModularButtonProps {
  primaryButton: ButtonType & { variant?: string };
  secondaryButton?: ButtonType;
}

export const ModularButton = ({ primaryButton, secondaryButton }: ModularButtonProps) => {
  const primaryInternalLink = useDocumentUrlWithNode(primaryButton?.link?.data);
  const secondaryInternalLink = useDocumentUrlWithNode(secondaryButton?.link?.data);
  const datalayer = useGTMDataLayer();
  const location = useLocation();
  const history = useHistory();

  const primaryLinkData = primaryButton.link?.data;
  const secondaryLinkData = secondaryButton?.link?.data;

  if (!primaryLinkData) return null;

  const primaryButtonExternalLink = {
    ...primaryLinkData,
    blank: primaryLinkData.blank ?? false,
    key: (primaryLinkData as ModularExternalLinkType).href ?? '',
  } as ModularExternalLinkType;
  const secondaryButtonExternalLink = secondaryLinkData
    ? ({
        ...secondaryLinkData,
        blank: secondaryLinkData.blank ?? false,
        key: (secondaryLinkData as ModularExternalLinkType).href ?? '',
      } as ModularExternalLinkType)
    : null;

  const handleTracking = (text: string, path: string) => {
    datalayer?.push({
      event: 'cta_click',
      cta_text: text,
      cta_location: location.pathname,
      cta_url: path,
      cta_position: 'content',
    });
  };

  const handleInternalLink = (text: string, path: string) => {
    handleTracking(text, path);
    history.push(path);
  };

  const renderButton = (
    text: string,
    variant: string,
    internalLink?: string,
    externalLink?: ModularExternalLinkType,
  ) => {
    if (!text) return null;

    if (internalLink) {
      return (
        <S.StyledButton variant={variant as ButtonVariant} onClick={() => handleInternalLink(text, internalLink)}>
          {text}
        </S.StyledButton>
      );
    }

    if (externalLink) {
      return (
        <S.StyledButton
          variant={variant as ButtonVariant}
          href={externalLink.href}
          target={externalLink.blank ? '_blank' : '_self'}
          onClick={() => handleTracking(text, externalLink.href)}
        >
          {text}
        </S.StyledButton>
      );
    }

    return null;
  };

  return (
    <S.ButtonContainer>
      {renderButton(
        primaryButton.buttonText,
        primaryButton.variant || 'signature',
        primaryInternalLink,
        primaryButtonExternalLink,
      )}
      {secondaryButton &&
        secondaryButton.buttonText &&
        renderButton(secondaryButton.buttonText, 'outline', secondaryInternalLink, secondaryButtonExternalLink)}
    </S.ButtonContainer>
  );
};
