import React from 'react';
import { BenefitHighlight } from '../../../BenefitHighlight/BenefitHighlight';
import { BenefitHighlightBlockType } from '../../../../../../types/BenefitHighlightBlockType';
import { ModularButton } from '../../../ModularButton/ModularButton';
import * as S from './Styles';

interface Props {
  block: BenefitHighlightBlockType;
}

export const MembershipBenefitHighlightListBlock = ({ block }: Props) => {
  const { membershipBenefitHighlightsList, title, variant, modularButtons, hasModularButtons } = block;

  return (
    <S.BenefitHighlights>
      {title ? <h2>{title}</h2> : null}
      <S.HighlightList $modularButtons={!!modularButtons?.primaryButton?.buttonText}>
        {membershipBenefitHighlightsList?.map((highlight) => (
          <BenefitHighlight
            key={`${highlight.title}-${highlight.variant}`}
            title={highlight.title}
            description={highlight.description}
            variant={variant}
            image={highlight.image}
            body={highlight.body}
            link={highlight.link}
          />
        ))}
      </S.HighlightList>
      {hasModularButtons && modularButtons.primaryButton?.buttonText ? (
        <ModularButton primaryButton={modularButtons.primaryButton} secondaryButton={modularButtons.secondaryButton} />
      ) : null}
    </S.BenefitHighlights>
  );
};
