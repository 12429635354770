import { ButtonLink } from '@naf/button-link';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing, themeLight } from '@naf/theme';
import React, { useState } from 'react';
import styled from 'styled-components';
import CarVariantFlyout from '../../components/bilguide/carVariants/CarVariantFlyout';

type DataEntryProps = { label: string; value: string | number; unit?: string } | null;

type Props = {
  tableTitle?: string;
  entries: DataEntryProps[];
  flyoutData?: any;
  hideDisclaimer?: boolean;
};

export const DataTable: React.FC<Props> = ({ tableTitle, entries, flyoutData, hideDisclaimer = false }) => {
  const [showVariantFlyout, setShowVariantFlyout] = useState<boolean>(false);
  return (
    <>
      {tableTitle && <Subheader variant={TextVariant.Header2}>{tableTitle}</Subheader>}
      {entries?.length > 0 && (
        <DataList>
          <>
            {entries
              .filter((entry) => entry !== null)
              .filter((entry) => !!entry.value)
              .map(({ label, value, unit }) => (
                <DataEntry key={label} label={label} value={value} unit={unit} />
              ))}
          </>
        </DataList>
      )}
      {Object.keys(flyoutData ?? {}).length > 0 && (
        <StyledButtonLink onClick={() => setShowVariantFlyout(true)} text="Flere spesifikasjoner" />
      )}
      {!hideDisclaimer && (
        <Disclaimer variant={TextVariant.Caption}>
          Opplysningene er hentet og gjengitt med samtykke fra Opplysningsrådet for veitrafikken AS,{' '}
          <a href="https://ofv.no/" target="_blank" rel="noreferrer">
            ofv.no
          </a>
          . Enkelte verdier kan avvike for spesifikke varianter av bilmodellen. Kontakt din forhandler for ytterligere
          informasjon.
        </Disclaimer>
      )}
      {showVariantFlyout && <CarVariantFlyout onHandleClose={() => setShowVariantFlyout(false)} data={flyoutData} />}
    </>
  );
};

const DataEntry = ({ label, value, unit }: DataEntryProps) => (
  <Entry>
    <Label variant={TextVariant.ArticleTextHeader}>{label}</Label>
    <Value variant={TextVariant.ArticleText}>
      {value === undefined ? (
        'Ikke målt'
      ) : (
        <>
          {value} {unit}
        </>
      )}
    </Value>
  </Entry>
);

const Subheader = styled(Text)`
  margin: 0;
  padding-bottom: ${spacing.space16};
  border-bottom: 1px solid ${({ theme }) => theme.border.subtle};
`;

const DataList = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const Entry = styled.div`
  padding: ${spacing.space16} 0;
  border-bottom: 1px solid ${({ theme }) => theme?.border?.subtle || themeLight.border.subtle};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const Label = styled(Text)`
  margin: 0;
  flex: 0 0 60%;
`;

const Value = styled(Text)`
  margin: 0;
  flex: 0 0 40%;

  @media (max-width: ${breakpoints.l}) {
    text-align: right;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  margin-top: ${spacing.space16};
  padding-bottom: ${spacing.space32};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.border.subtle};
`;

const Disclaimer = styled(Text)`
  margin: 0;
  margin-top: ${spacing.space16};
  line-height: 1.5;
`;
