import React from 'react';
import { useHistory } from 'react-router';
import {
  ProductContainer,
  ProductImage,
  ProductImageWrapper,
  ProductInfo,
  ProductIngress,
  ProductName,
} from './Styles';
import { ConsumerTestProductType } from '../../../../../../../../types/ConsumerTestProductType';
import { useGTMDataLayer } from '../../../../../../hooks/GTM/useGTMDataLayer';
import { cloudinaryImage } from '../../../../../../utils/imageUrl';
import useSelector from '../../../../../../redux/typedHooks';

interface Props {
  product: ConsumerTestProductType;
  category: string;
}

export const ConsumerTestProduct = ({ product, category }: Props) => {
  const { title, ingress, image, internalLink } = product;
  const app = useSelector((state) => state.application);
  const history = useHistory();
  const dataLayer = useGTMDataLayer();
  if (!title) return null;

  const itemImage = cloudinaryImage(image?.public_id, app);

  return (
    <ProductContainer
      onClick={() => {
        const location = window?.location;
        dataLayer.push({
          event: 'link_click',
          link_text: title,
          link_location: location.pathname,
        });
        history.push(`/forbrukertester/${category}/${internalLink.slug}`);
      }}
    >
      {itemImage && image ? (
        <ProductImageWrapper>
          <ProductImage src={itemImage} alt={image?.altText || ''} />
        </ProductImageWrapper>
      ) : null}
      <ProductInfo>
        <ProductName>{title}</ProductName>
        <ProductIngress>{ingress}</ProductIngress>
      </ProductInfo>
    </ProductContainer>
  );
};
