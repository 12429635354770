import React, { useMemo } from 'react';
import { Text, TextVariant } from '@naf/text';
import { animation, breakpoints, spacing } from '@naf/theme';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { BilguideImage, CarModelType } from '../../../../types/carModelType';
import { toCloudinaryPath } from '../../components/bilguide/cloudinary/cloudinary';
import { useCloudinary } from '../../hooks/Cloudinary/useCloudinary';

type Variant = 'previous' | 'next' | 'list-item';

type Props = {
  model: CarModelType;
  isCurrent?: boolean;
  inSidebar?: boolean;
  badge?: string;
  variant: Variant;
};

type Image = { imageType: 'preferredImage'; image: CloudinaryImage } | { imageType: 'ofvImage'; image: BilguideImage };

export const RelatedModel: React.FC<Props> = ({ model, isCurrent, inSidebar, badge, variant }) => {
  const { vehicleModelPictures, name, brandName, modelName, slug, segment, meta, categories, preferredPicture } = model;
  const displayName = name || meta?.name || `${brandName} ${modelName} `;

  const nafCategories = categories || meta?.categories;
  const cld = useCloudinary();

  const { image, imageType }: Image = useMemo(() => {
    if (preferredPicture && !Array.isArray(preferredPicture)) {
      const cldImage =
        preferredPicture &&
        preferredPicture.publicId &&
        cld.image(preferredPicture.publicId).resize(fill().width(50).height(38)).quality('auto:eco').format('auto');
      return { image: cldImage, imageType: 'preferredImage' };
    }
    const ofvImage = vehicleModelPictures?.find((pic: any) => pic.type === 'smallFront');
    return { imageType: 'ofvImage', image: ofvImage };
  }, [preferredPicture, vehicleModelPictures, cld]);

  return (
    <StyledLink to={`/bilguiden/bilmodell/${slug}`} $current={isCurrent} variant={variant} $inSidebar={inSidebar}>
      {badge && <Badge variant={TextVariant.TagSmall}>{badge}</Badge>}
      <Inner>
        <div>
          <Tags variant={TextVariant.Tiny}>
            {nafCategories && nafCategories.length > 0
              ? nafCategories.map(({ category }) => category).join(', ')
              : segment.join(', ')}
          </Tags>
          <CarName tag="p" variant={TextVariant.CaptionHeader}>
            {displayName}
          </CarName>
        </div>
        <ImageContainer $variant={variant}>
          {imageType === 'preferredImage' ? (
            <AdvancedImage
              cldImg={image}
              alt={preferredPicture.altText || ''}
              plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 })]}
            />
          ) : (
            image?.path && <img src={toCloudinaryPath(image?.path, 'Thumbnail')} alt={image?.alternateText} />
          )}
        </ImageContainer>
      </Inner>
    </StyledLink>
  );
};

const Tags = styled(Text)`
  margin: 0;
`;

const StyledLink = styled(Link)<{ $current: boolean; $inSidebar?: boolean; variant: Variant }>`
  ${({ variant }) =>
    variant === 'next' || variant === 'previous'
      ? css`
          width: 100%;
          flex: 1 1 0;
          position: relative;
        `
      : css`
          border-top: 1px solid ${({ theme }) => theme.border.subtle};
        `}
  padding: ${spacing.space16} ${spacing.space16} ${spacing.space16} ${spacing.space24};
  text-decoration: none;

  ${({ $current }) =>
    $current
      ? css`
          background: ${({ theme }) => theme.componentColors.cta.disabled};
          :hover {
            cursor: default;
            color: ${({ theme }) => theme.typography.defaultText};
          }
        `
      : css`
          :hover {
            background: ${({ theme }) => theme.componentColors.cta.disabled};
            img {
              transform: scale(105%);
            }`}
  }

  @media (max-width: ${breakpoints.m}) {
    ${({ variant }) => {
      if (variant === 'previous' || variant === 'next') {
        return css`
          padding: ${spacing.space16} 0 ${spacing.space16} ${spacing.space16};
        `;
      }
      return null;
    }}`;

const CarName = styled(Text)`
  margin: 0;
`;

const Badge = styled(Text)`
  position: absolute;
  background: white;
  padding: 2px 4px;
  top: -8px;
  border-radius: 2px;
  color: ${({ theme }) => theme.typography.defaultText};
  border: 1px solid ${({ theme }) => theme.border.subtle};
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacing.space8};
  align-items: center;
`;

const ImageContainer = styled.div<{ $variant?: Variant }>`
  overflow: hidden;
  height: 37.5px;
  width: 50px;
  background: ${({ theme }) => theme.border.subtle};
  img {
    width: 50px;
    height: 37.5px;
    object-fit: cover;
    border-radius: 2px;
    transition: transform 0.6s ${animation.easeIn};
  }

  @media (max-width: ${breakpoints.l}) {
    display: ${({ $variant }) => ($variant === 'list-item' ? 'block' : 'none')};
  }
`;
