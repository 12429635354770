import { breakpoints } from '@naf/theme';
import styled from 'styled-components';

// Width is set to overlap the 44px width of NAF logo
export const EasterWrapper = styled.div<{ $isScrolled?: boolean }>`
  height: auto;
  width: 103px;
  transform: translateX(-9px);
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;

  @media (min-width: ${breakpoints.m}) {
    transform: translateX(-6px);
  }

  @media (min-width: ${breakpoints.l}) {
    width: 112px;
    transform: translate(7px, 8px);
    ${({ $isScrolled }) =>
      $isScrolled &&
      `
				width: 84px;
				transform: translate(11px, 0px);
			`}
  }
`;
