/* eslint-disable camelcase */
import { Button } from '@naf/button';
import { Grid } from '@naf/grid';
import { Input, Label } from '@naf/input';
import axios, { AxiosError } from 'axios';
import { Text, TextVariant } from '@naf/text';
import { UserInteraction, UserInteractionVariant } from '@naf/user-interaction';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import { useNpsResponse } from '../../hooks/NpsService';
import { useAuth0Token } from '../../hooks/useAuth0Token';
import { DEFAULT_IMG_URL } from '../../lib/constants';
import { useWindowLocation } from '../../hooks/Window/useWindowLocation';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';

export function isAxiosError<ResponseType>(error: unknown): error is AxiosError<ResponseType> {
  return axios.isAxiosError(error);
}

const NPS = () => {
  const location = useLocation();
  const windowPath = useWindowLocation();
  const [responseId, setResponseId] = React.useState<string | null>(null);
  const [feedback, setFeedback] = useState('');
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { simpleToken } = useAuth0Token();
  const { postResponseWithCustomerId, patchResponse, isSubmitted, isLoading, isPatched, error } = useNpsResponse({
    token: simpleToken,
  });
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    patchResponse({
      responseId: responseId as string,
      action: feedback,
    });
  };
  useEffect(() => {
    // first post score on mount, then save reponse id to use in patch with feedback text
    postResponseWithCustomerId({
      surveyId: params.get('surveyId') as string,
      score: params.get('score') as string,
      utm_source: params.get('utm_source') || 'naf.no',
      utm_medium: params.get('utm_medium') || 'web',
      utm_campaign: params.get('utm_campaign') || 'nps_medlemskap',
      utm_content: params.get('utm_content') || 'v1',
      customerId: params.get('customerGuid') || '',
    }).then((response) => response && setResponseId(response.id));
  }, [params, postResponseWithCustomerId]);
  const alreadyAnswered = error?.message === 'Already answered';

  return (
    <LayoutWithMainContent
      title="NPS landingsside"
      description=""
      url={windowPath}
      imgUrl={DEFAULT_IMG_URL}
      gtmArgs={{
        page_type: 'NPS',
        pageCategory: 'NPS',
        contentId: '',
      }}
      isHiddenFromSearch
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    >
      {isSubmitted && (
        <Grid>
          <StyledForm>
            <Text variant={TextVariant.Header1}>Takk for svaret ditt, du inspirerer oss</Text>
            {!isPatched && (
              <>
                <Label htmlFor="feedback">Kan du utdype hvorfor du gir dette svaret?</Label>
                <Input value={feedback} width={323} as="textarea" maxCharacters={500} onChange={setFeedback} />
                <Button type="button" onClick={handleSubmit} isLoading={isLoading}>
                  Send tilbakemelding
                </Button>
              </>
            )}
          </StyledForm>
        </Grid>
      )}
      {alreadyAnswered && (
        <UserInteraction variant={UserInteractionVariant.Info} title="Du har allerede svart på denne undersøkelsen" />
      )}
      {!alreadyAnswered && error && (
        <UserInteraction variant={UserInteractionVariant.Error} title="Noe gikk galt, prøv igjen senere">
          {isAxiosError<{ reason: string }>(error) ? `Feilmelding: ${error?.response?.data?.reason}` : ''}
        </UserInteraction>
      )}
    </LayoutWithMainContent>
  );
};

const StyledForm = styled.div`
  grid-column: 2 / 11;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export default NPS;
