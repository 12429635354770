import { Action, ActionType, createAction, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import { ajax } from 'rxjs/ajax';
import Boom from '@hapi/boom';
import { TypedEpic } from '../types';
import {
  ConsumerGuidesListResponse,
  ConsumerGuideDetail,
  ConsumerTestedProduct,
  ConsumerTestedProductsResponse,
} from '../../../../types/consumerTestType';

// Actions
export enum Actions {
  // Get all guides
  GET_CONSUMER_GUIDES = 'naf/GET_CONSUMER_GUIDES',
  GET_CONSUMER_GUIDES_SUCCESS = 'naf/GET_CONSUMER_GUIDES_SUCCESS',
  GET_CONSUMER_GUIDES_FAIL = 'naf/GET_CONSUMER_GUIDES_FAIL',
  GET_CONSUMER_GUIDES_CANCEL = 'naf/GET_CONSUMER_GUIDES_CANCEL',
  // Get specific guide
  GET_ONE_GUIDE = 'naf/GET_ONE_GUIDE',
  GET_ONE_GUIDE_SUCCESS = 'naf/GET_ONE_GUIDE_SUCCESS',
  GET_ONE_GUIDE_FAIL = 'naf/GET_ONE_GUIDE_FAIL',
  GET_ONE_GUIDE_CANCEL = 'naf/GET_ONE_GUIDE_CANCEL',
  // Get products from specific guide
  GET_TESTED_PRODUCTS = 'naf/GET_TESTED_PRODUCTS',
  GET_TESTED_PRODUCTS_SUCCESS = 'naf/GET_TESTED_PRODUCTS_SUCCESS',
  GET_TESTED_PRODUCTS_FAIL = 'naf/GET_TESTED_PRODUCTS_FAIL',
  GET_TESTED_PRODUCTS_CANCEL = 'naf/GET_TESTED_PRODUCTS_CANCEL',
  // Get specific tested product
  GET_ONE_PRODUCT = 'naf/GET_ONE_PRODUCT',
  GET_ONE_PRODUCT_SUCCESS = 'naf/GET_ONE_PRODUCT_SUCCESS',
  GET_ONE_PRODUCT_FAIL = 'naf/GET_ONE_PRODUCT_FAIL',
  GET_ONE_PRODUCT_CANCEL = 'naf/GET_ONE_PRODUCT_CANCEL',
  // Get related products from specific guide to show in sidebar
  GET_RELATED_PRODUCTS = 'naf/GET_RELATED_PRODUCTS',
  GET_RELATED_PRODUCTS_SUCCESS = 'naf/GET_RELATED_PRODUCTS_SUCCESS',
  GET_RELATED_PRODUCTS_FAIL = 'naf/GET_RELATED_PRODUCTS_FAIL',
  GET_RELATED_PRODUCTS_CANCEL = 'naf/GET_RELATED_PRODUCTS_CANCEL',
  // Clear selected guide
  CLEAR_SELECTED_GUIDE = 'naf/CLEAR_SELECTED_GUIDE',
  // Clear tested products
  CLEAR_TESTED_PRODUCTS = 'naf/CLEAR_TESTED_PRODUCTS',
  // Clear tested products
  CLEAR_RELATED_PRODUCTS = 'naf/CLEAR_RELATED_PRODUCTS',
}

export interface State {
  data: ConsumerGuidesListResponse;
  selectedGuide?: ConsumerGuideDetail;
  oneProduct?: ConsumerTestedProduct;
  testedProducts?: ConsumerTestedProductsResponse;
  relatedProducts?: ConsumerTestedProductsResponse;
  isUpdatingGuide?: boolean;
  isUpdatingProducts?: boolean;
  errorState?: any;
}

export const initialState: State = {
  data: {
    items: [],
  },
  selectedGuide: {},
  oneProduct: {},
  testedProducts: { items: [], itemsCount: 0, filters: [] },
  relatedProducts: { items: [], itemsCount: 0 },
  isUpdatingGuide: false,
  isUpdatingProducts: false,
  errorState: null,
};

export const actions = {
  getConsumerGuides: createAsyncAction(
    Actions.GET_CONSUMER_GUIDES, // request payload creator
    Actions.GET_CONSUMER_GUIDES_SUCCESS, // success payload creator
    Actions.GET_CONSUMER_GUIDES_FAIL, // failure payload creator
    Actions.GET_CONSUMER_GUIDES_CANCEL, // optional cancel payload creator
  )<{ token?: string }, ConsumerGuidesListResponse, Error, undefined>(),
  getOneGuide: createAsyncAction(
    Actions.GET_ONE_GUIDE, // request payload creator
    Actions.GET_ONE_GUIDE_SUCCESS, // success payload creator
    Actions.GET_ONE_GUIDE_FAIL, // failure payload creator
    Actions.GET_ONE_GUIDE_CANCEL, // optional cancel payload creator
  )<[string, { token?: string }], ConsumerGuideDetail, Error, undefined>(),
  getTestedProducts: createAsyncAction(
    Actions.GET_TESTED_PRODUCTS, // request payload creator
    Actions.GET_TESTED_PRODUCTS_SUCCESS, // success payload creator
    Actions.GET_TESTED_PRODUCTS_FAIL, // failure payload creator
    Actions.GET_TESTED_PRODUCTS_CANCEL, // optional cancel payload creator
  )<[string, { token?: string }], ConsumerTestedProductsResponse, Error, undefined>(),
  getOneProduct: createAsyncAction(
    Actions.GET_ONE_PRODUCT, // request payload creator
    Actions.GET_ONE_PRODUCT_SUCCESS, // success payload creator
    Actions.GET_ONE_PRODUCT_FAIL, // failure payload creator
    Actions.GET_ONE_PRODUCT_CANCEL, // optional cancel payload creator
  )<[string, { token?: string }], ConsumerTestedProduct, Error, undefined>(),
  getRelatedProducts: createAsyncAction(
    Actions.GET_RELATED_PRODUCTS, // request payload creator
    Actions.GET_RELATED_PRODUCTS_SUCCESS, // success payload creator
    Actions.GET_RELATED_PRODUCTS_FAIL, // failure payload creator
    Actions.GET_RELATED_PRODUCTS_CANCEL, // optional cancel payload creator
  )<string, ConsumerTestedProductsResponse, Error, undefined>(),
  clearTestedProducts: createAction(Actions.CLEAR_TESTED_PRODUCTS)(),
  clearRelatedProducts: createAction(Actions.CLEAR_RELATED_PRODUCTS)(),
  clearSelectedGuide: createAction(Actions.CLEAR_SELECTED_GUIDE)(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  // Get all guides
  .handleAction(actions.getConsumerGuides.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdatingGuide = true;
    }),
  )
  .handleAction(actions.getConsumerGuides.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdatingGuide = false;
      draftState.data = action.payload;
    }),
  )
  .handleAction(actions.getConsumerGuides.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdatingGuide = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getConsumerGuides.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdatingGuide = false;
    }),
  )
  // Get specific guide
  .handleAction(actions.getOneGuide.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdatingGuide = true;
      draftState.errorState = false;
    }),
  )
  .handleAction(actions.getOneGuide.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdatingGuide = false;
      draftState.errorState = false;
      draftState.selectedGuide = action.payload;
    }),
  )
  .handleAction(actions.getOneGuide.failure, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdatingGuide = false;
      draftState.errorState = true;
    }),
  )
  .handleAction(actions.getOneGuide.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdatingGuide = false;
    }),
  )
  // Get tested products in specific guide
  .handleAction(actions.getTestedProducts.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdatingProducts = true;
    }),
  )
  .handleAction(actions.getTestedProducts.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdatingProducts = false;
      draftState.testedProducts = {
        items: action.payload.items || [],
        itemsCount: action.payload.itemsCount || 0,
        filters: action.payload.filters || [],
      };
    }),
  )
  .handleAction(actions.getTestedProducts.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdatingProducts = false;
      draftState.errorState = action.payload.message;
    }),
  )
  .handleAction(actions.getTestedProducts.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdatingProducts = false;
    }),
  )
  // Get single tested product
  .handleAction(actions.getOneProduct.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdatingProducts = true;
    }),
  )
  .handleAction(actions.getOneProduct.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdatingProducts = false;
      draftState.oneProduct = action.payload;
    }),
  )
  .handleAction(actions.getOneProduct.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdatingProducts = false;
      draftState.errorState = action.payload.message;
    }),
  )
  .handleAction(actions.getOneProduct.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdatingProducts = false;
    }),
  )
  // Get related products in specific guide to show in sidebar
  .handleAction(actions.getRelatedProducts.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdatingProducts = true;
    }),
  )
  .handleAction(actions.getRelatedProducts.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdatingProducts = false;
      draftState.relatedProducts = {
        items: action.payload.items || [],
        itemsCount: action.payload.itemsCount || 0,
      };
    }),
  )

  .handleAction(actions.getRelatedProducts.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdatingProducts = false;
      draftState.errorState = action.payload.message;
    }),
  )
  .handleAction(actions.getRelatedProducts.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdatingProducts = false;
    }),
  )
  // Clear selected guide
  .handleAction(actions.clearSelectedGuide, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.selectedGuide = undefined;
    }),
  )
  .handleAction(actions.clearTestedProducts, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.testedProducts = { items: [], itemsCount: 0, filters: [] };
    }),
  )
  .handleAction(actions.clearRelatedProducts, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.relatedProducts = { items: [], itemsCount: 0 };
    }),
  );

// Get all consumer guides for listing.
export const getConsumerGuidesEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimNafNoApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_CONSUMER_GUIDES),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getConsumerGuides.request>[]
    >,
    switchMap(([action]) => {
      const url = `${apimBaseUrl}/${apimNafNoApi}/consumertest/guides`;

      const headers: {
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Ocp-Apim-Subscription-Key': apimContentHub,
        ...(action.payload?.token ? { Authorization: `Bearer ${action.payload.token}` } : {}),
      };

      return ajax<ConsumerGuidesListResponse>({
        url,
        headers,
      }).pipe(
        map(({ response }) => actions.getConsumerGuides.success(response)),
        catchError(() => of(actions.getConsumerGuides.failure(new Boom.Boom('Could not get guides.')))),
      );
    }),
  );
};

// Get a specific consumer guide.
const getOneConsumerGuideEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub } = state$.value.application;

  return action$.pipe(
    ofType(Actions.GET_ONE_GUIDE),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getOneGuide.request>[]
    >,
    switchMap(([action]) => {
      const slug = action.payload;
      const token = action.meta?.token;

      // Dispatch clearTestedProducts first, then fetch the guide
      return of(actions.clearTestedProducts()).pipe(
        concatMap(() =>
          ajax<ConsumerGuideDetail>({
            url: `${apimBaseUrl}/contenthub-nafno/commonarticles/${slug}`,
            headers: {
              'Ocp-Apim-Subscription-Key': apimContentHub,
              ...(token ? { Authorization: `Bearer ${token}` } : {}),
            },
          }).pipe(
            map(({ response }) => actions.getOneGuide.success(response)),
            catchError((error) => of(actions.getOneGuide.failure(error.message))),
          ),
        ),
      );
    }),
  );
};

// Get products from a specific consumer guide.
const getTestedProductsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub } = state$.value.application;

  return action$.pipe(
    ofType(Actions.GET_TESTED_PRODUCTS),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getTestedProducts.request>[]
    >,
    switchMap(([action]) => {
      const id = action.payload;
      const token = action.meta?.token;

      // Dispatch clearTestedProducts before fetching new tested products
      return of(actions.clearTestedProducts()).pipe(
        concatMap(() =>
          ajax<ConsumerTestedProductsResponse>({
            url: `${apimBaseUrl}/contenthub-nafno/consumertest/products/${id}`,
            headers: {
              'Ocp-Apim-Subscription-Key': apimContentHub,
              ...(token ? { Authorization: `Bearer ${token}` } : {}),
            },
          }).pipe(
            map(({ response }) => actions.getTestedProducts.success(response)),
            catchError((error) => of(actions.getTestedProducts.failure(error.message))),
          ),
        ),
      );
    }),
  );
};

// Get one product. Used to showcase on tested product page.
const getOneProductEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub } = state$.value.application;

  return action$.pipe(
    ofType(Actions.GET_ONE_PRODUCT),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getOneProduct.request>[]
    >,
    switchMap(([action]) => {
      const slug = action.payload;
      const token = action.meta?.token;

      return ajax<ConsumerTestedProduct>({
        url: `${apimBaseUrl}/contenthub-nafno/commonarticles/${slug}`,
        headers: {
          'Ocp-Apim-Subscription-Key': apimContentHub,
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      }).pipe(
        map(({ response }) => actions.getOneProduct.success(response)),
        catchError((error) => of(actions.getOneProduct.failure(error.message))),
      );
    }),
  );
};

// Get related products from a specific consumer guide.
const getRelatedProductsEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub } = state$.value.application;

  return action$.pipe(
    ofType(Actions.GET_RELATED_PRODUCTS),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getRelatedProducts.request>[]
    >,
    switchMap(([action]) => {
      const id = action.payload;
      return of(actions.clearTestedProducts()).pipe(
        concatMap(() =>
          ajax<ConsumerTestedProductsResponse>({
            url: `${apimBaseUrl}/contenthub-nafno/consumertest/products/${id}?minimal=true`,
            headers: {
              'Ocp-Apim-Subscription-Key': apimContentHub,
            },
          }).pipe(
            map(({ response }) => actions.getRelatedProducts.success(response)),
            catchError((error) => of(actions.getRelatedProducts.failure(error.message))),
          ),
        ),
      );
    }),
  );
};

export const epics: TypedEpic[] = [
  getConsumerGuidesEpic,
  getOneConsumerGuideEpic,
  getTestedProductsEpic,
  getRelatedProductsEpic,
  getOneProductEpic,
];
