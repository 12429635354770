import React from 'react';
import { DoubleImageSerializerProps } from './DoubleImageSerializerProps';
import { DoubleImageBlock } from './DoubleImageBlock';

export const DoubleImageSerializer =
  () =>
  ({ value }: DoubleImageSerializerProps) =>
    <DoubleImageBlock value={value} />;

// TODO: Hvis denne skal tas i bruk for annen type brødtekst enn ny artikkelmal må denne nok forbedres noe
