import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { ConsumerTestCTA, ConsumerTestCtaWrapper, ProductListContainer, Title } from './Styles';
import { ConsumerTestProduct } from './ConsumerTestProduct';
import { ConsumerTestProductListType } from '../../../../../../../../types/ConsumerTestProductType';
import { ArticleBlockContentGridCol } from '../ArticleBlockContentGridCol';

interface Props {
  value: ConsumerTestProductListType;
}

export const ConsumerTestProductList = ({ value }: Props) => {
  const { title, products, numProducts, sortBy, internalLink } = value;
  const history = useHistory();

  const sortedAndTrimmedProducts = useMemo(
    () =>
      [...products]
        .sort((a, b) => {
          switch (sortBy) {
            case 'sortByPoints':
              return a.scoreValue - b.scoreValue;
            case 'sortByPrice':
              return a.priceValue - b.priceValue;
            case 'sortByName':
              return a.title.localeCompare(b.title);
            default:
              return 0;
          }
        })
        .slice(0, numProducts ?? products.length),
    [products, sortBy, numProducts],
  );

  const handleCTAClick = () => {
    history.push(`/${internalLink?.parent?.slug ?? 'forbrukertester'}/${internalLink?.slug}`);
  };

  return (
    <ProductListContainer>
      <Title>{title}</Title>
      {sortedAndTrimmedProducts.map((product) => (
        <ConsumerTestProduct key={product.id} product={product} category={internalLink.slug} />
      ))}
      <ConsumerTestCtaWrapper>
        <ConsumerTestCTA text={internalLink.name} onClickFn={handleCTAClick} variant="secondary" />
      </ConsumerTestCtaWrapper>
    </ProductListContainer>
  );
};

export const ConsumerTestProductListSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: Props) => (
      <ArticleBlockContentGridCol>
        <ConsumerTestProductList value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: Props) => <ConsumerTestProductList value={value} />;
};
