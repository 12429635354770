import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import StepWizard from 'react-step-wizard';

import useSelector from '../../../../redux/typedHooks';
import { useAuth0Token } from '../../../../hooks/useAuth0Token';
import { Receipt } from './steps/Receipt';
import { Confirmation } from './steps/Confirmation';
import { FormStep } from './steps/FormStep';
import { useGTMDataLayer } from '../../../../hooks/GTM/useGTMDataLayer';
import { FormBlockProps } from './FormBlock.types';
import { Wrapper } from './FormBlock.styles';
import { getEventPayload } from './FormBlock.utils';

export const FormBlock = ({ value: { dynamicForm }, centerAlignReceipt }: FormBlockProps) => {
  const { fieldComponents, textField, title, body, buttonText, options } = dynamicForm || {};

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
    getValues,
    watch,
    setValue,
    setError,
  } = useForm({ mode: 'onSubmit' });

  const CH_WEB_APIM_CONTENTHUB = useSelector((state) => state.application.apimContentHub);
  const { simpleToken } = useAuth0Token();

  const [errorState, setErrorState] = useState<string | null>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dataLayer = useGTMDataLayer();

  const topElement = useRef<HTMLDivElement | null>(null);

  const onSubmit = async (data: Record<string, string> | { file: File[] }) => {
    const formData = new FormData();
    try {
      setIsSubmitting(true);

      const headers: {
        'Content-Type': string;
        'Ocp-Apim-Subscription-Key': string;
        Authorization?: string;
      } = {
        'Content-Type': 'multipart/form-data',
        'Ocp-Apim-Subscription-Key': CH_WEB_APIM_CONTENTHUB,
      };
      // Append all field values, except file attachments, to formData
      const mappedData = Object.entries(data)
        .filter(([key]) => key !== 'file')
        .map(([key, value]) => ({ id: key, value }));
      const formDataValue = { fields: mappedData };

      formData.append('fields', new Blob([JSON.stringify(formDataValue)], { type: 'application/json' }));

      // Append file attachments to formData
      if (data.file && data.file.length > 0) {
        (data.file as File[]).forEach((file) => formData.append('file', file, file.name));
      }

      if (simpleToken) {
        headers.Authorization = `Bearer ${simpleToken}`;
      }
      await axios.request({
        url: options?.endpoint,
        method: options?.httpMethod || 'POST',
        headers,
        data: formData,
      });

      setIsSubmitting(false);
      setIsSuccess(true);
      setErrorState(null);

      if (dataLayer) {
        const eventPayload = getEventPayload(formDataValue, dynamicForm);
        dataLayer.push(eventPayload);
      }
      return true;
    } catch (e: unknown) {
      const errorMessage =
        axios.isAxiosError(e) &&
        Array.isArray(e.response?.data) &&
        e.response?.data?.find((el: { errorMessage?: string | null }) => Boolean(el?.errorMessage))?.errorMessage;
      if (errorMessage) {
        setErrorState(errorMessage);
      } else {
        setErrorState(
          'Her har det gått galt for oss. Vi støtte på en feil på vår side. Prøv igjen senere, og kontakt kundeservice om problemet vedvarer.',
        );
      }
      setIsSubmitting(false);
      setIsSuccess(false);
      return false;
    }
  };

  const onStepChange = () => {
    topElement.current?.scrollIntoView();
  };

  return fieldComponents ? (
    <Wrapper ref={topElement}>
      <StepWizard transitions={{}} isLazyMount onStepChange={onStepChange}>
        <FormStep
          watch={watch}
          fieldComponents={fieldComponents}
          textField={textField}
          title={title}
          body={body}
          clearErrors={clearErrors}
          errors={errors}
          control={control}
          getValues={getValues}
          setValue={setValue}
          handleSubmit={handleSubmit}
          buttonText={buttonText}
          onSubmit={onSubmit}
          shouldConfirm={!!options?.confirmationPage?.body}
          hasReceiptPage={!!options?.receiptPage?.body}
          isSubmitting={isSubmitting}
          errorState={errorState}
          isSuccess={isSuccess}
          setError={setError}
        />
        {options?.confirmationPage?.body ? (
          <Confirmation
            confirmationPageBody={options.confirmationPage.body}
            getValues={getValues}
            onSubmit={onSubmit}
            buttonText={options?.confirmationPage?.buttonText}
            isSuccess={isSuccess}
            isSubmitting={isSubmitting}
            errorState={errorState}
          />
        ) : null}
        {options?.receiptPage?.body ? (
          <Receipt receiptPageBody={options.receiptPage.body} centerAlignReceipt={centerAlignReceipt} />
        ) : null}
      </StepWizard>
    </Wrapper>
  ) : null;
};
