import { Button } from '@naf/button';
import { breakpoints, spacing } from '@naf/theme';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  white-space: nowrap;
  display: inline-flex;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${spacing.space16};
  margin-top: ${spacing.space24};

  @media (max-width: ${breakpoints.s}) {
    flex-direction: column;
    gap: ${spacing.space24};
  }
`;
