export enum NAFGTMEvent {
  checkoutFunnel = 'checkout_funnel',
  checkoutAction = 'checkout_action',
  beginCheckout = 'begin_checkout',
  purchase = 'purchase',
  addShippingInfo = 'add_shipping_info',
  bilmodell = 'bilmodell',
  viewItem = 'view_item',
  accordionOpen = 'accordion_open',
  tabNavigation = 'tab_navigation',
  abExperimentParticipant = 'ab_experiment_participant',
  virtualPageView = 'virtualPageView',
}
