import T from './carTerms';

export const carTermsMapper = (term: string) => {
  switch (term) {
    case T.FUEL_CATEGORIES:
      return 'Motortype';
    case T.DRIVE_TRAIN:
      return 'Drivhjul';
    case T.CHASSIS:
      return 'Chassis';
    case T.RANGE_WLTP:
      return 'Rekkevidde (WLTP)';
    case T.CARGO_SPACE_VOLUME:
      return 'Bagasjerom';
    case T.NORMAL_CARGOSPACE_VOLUME:
      return 'Bagasjerom';
    case T.MAX_CARGOSPACE_VOLUME:
      return 'Bagasjerom med bakerste seterad nede';
    case T.GENERAL:
      return 'Generelt';
    case T.PRICE:
      return 'Pris';
    case T.ENGINE_TYPE:
      return 'Motortype';
    case T.ENERGY_SOURCE:
      return 'Energikilde';
    case T.GEARBOX:
      return 'Girkasse';
    case T.NUMBER_OF_GEARS:
      return 'Antall gir';
    case T.TOTAL_TORQUE:
      return 'Totalt dreiemoment';
    case T.BREAK_HORSE_POWER:
      return 'Hestekrefter';
    case T.ZERO_TO_HUNDRED:
      return '0-100';
    case T.EURONCAP:
      return 'EuroNCAP score';
    case T.NUMBER_OF_COLLISIONS_BAG:
      return 'Antall kollisjonsputer';
    case T.WARRANTY:
      return 'Garanti';
    case T.WARRANTY_YEARS:
      return 'Årsgaranti';
    case T.WARRANTY_KM:
      return 'Garanti';
    case T.SERVICE_INTERVAL:
      return 'Serviceintervall';
    case T.ELECTRIC_EMISSION_DETAILS:
      return 'Rekkevidde/forbruk/lading';
    case T.AVERAGE_CONSUMPTION_WLTP:
      return 'Drivstofforbruk (WLTP)';
    case T.BATTERY:
      return 'Batteri';
    case T.BATTERY_CAPACITY:
      return 'Batterikapasitet';
    case T.BATTERY_VOLTAGE_V:
      return 'Spenning';
    case T.CHARGE_VOLTAGE_V:
      return 'Ladespenning';
    case T.BATTERY_WARANTY:
      return 'Batterigaranti';
    case T.BATTERY_WARANTY_YEARS:
      return 'Batterigaranti år';
    case T.BATTERY_WARANTY_KM:
      return 'Batterigaranti km';
    case T.BATTERY_WEIGHT:
      return 'Batteri vekt';
    case T.CAPACITY_KWH:
      return 'Kapasitet';
    case T.GUARANTEED_RESIDUAL_CAPACITY:
      return 'Garantert restkapasitet';
    case T.CO2_EMISSION_WLTP:
      return 'CO2-utslipp (WLTP)';
    case T.CHARGING_TIME_FAST:
      return 'Ladetid hurtig';
    case T.CHARGING_TIME_NAF:
      return 'Ladetid NAF';
    case T.CHARGING_TIME_NORMAL:
      return 'Ladetid';
    case T.BATTERY_RENTAL_PRICE:
      return 'Batterileie, pris 10 000 km/år';
    case T.ELECTRIC_CONSUMPTION_WLTP:
      return 'Strømforbruk (WLTP)';
    case T.FAST_CHARGER:
      return 'Hurtiglader';
    case T.MAX_POWER_CHARGE:
      return 'Maksimal ladeeffekt';
    case T.NOX_EMISSION_NORMAL:
      return 'NOx-utslipp (normalisert)';
    case T.NOX_EMISSION_WLTP:
      return 'NOx-utslipp (WLTP)';
    case T.ON_BOARD_CHARGER:
      return 'Ombordlader';
    case T.PRICE_PER_KM_RANGE:
      return 'Pris per km rekkevidde';
    case T.RANGE_NAF:
      return 'Rekkevidde (NAF)';
    case T.RANGE_NEDC:
      return 'Rekkevidde (NEDC)';
    case T.SIZE_AND_WEIGHT: /// ???
      return 'Størrelse og vekt';
    case T.ALLOWED_TOTAL_WEIGHT:
      return 'Tillatt totalvekt ';
    case T.AXEL_DISTANCE:
      return 'Akselavstand';
    case T.GROUND_CLEARANCE:
      return 'Bakkeklaring';
    case T.HEIGHT:
      return 'Høyde';
    case T.LENGTH:
      return 'Lengde';
    case T.MAX_TOW_WEIGHT:
      return 'Maksimal tilhengervekt';
    case T.NUMBER_OF_DOORS:
      return 'Antall dører';
    case T.NUMBER_OF_SEATS:
      return 'Antall sitteplasser';
    case T.PAYLOAD:
      return 'Nyttelast';
    case T.MAX_ROOF_PAYLOAD:
      return 'Maks taklast';
    case T.TRAILER_HITCH:
      return 'Hengerfeste';
    case T.TRAILER_PRICE:
      return 'Pris for hengerfeste';
    case T.WEIGHT:
      return 'Egenvekt uten fører';
    case T.WIDTH:
      return 'Bredde';
    case T.EQUIPMENT:
      return 'Utstyr';
    case T.EQUIPMENT_COMMENT:
      return 'Kommentar';
    case T.NAVIGATION_SYSTEM:
      return 'Navigasjonssystem';
    case T.CRUISE_HITCH:
      return 'Fartsholder';
    case T.REAR_CAMERA:
      return 'Ryggekamera';
    case T.TIRE_PRESSURE_MONITOR:
      return 'Dekktrykk-målesystem (TPMS)';
    case T.WHEEL_DIM_FRONT:
      return 'Dekkdimensjon foran';
    case T.WHEEL_DIM_REAR:
      return 'Dekkdimensjon bak';
    case T.RIM_DIM_FRONT:
      return 'Felgdimensjon foran';
    case T.EXTERNAL_CHARGING:
      return 'Ekstern lading';
    case T.ELECTRIC_CONSUMPTION_WLTP_PER100KM:
      return 'Strømforbruk (WLTP)';
    case T.ELECTRIC_CONSUMPTION_NEDC_PER100KM:
      return 'Strømforbruk (NEDC)';
    case T.RIM_DIM_REAR:
      return 'Felgdimensjon bak';
    case T.CONNECTION_TYPE:
      return 'Hurtiglader';
    case T.CHARGING_CABLE:
      return 'Original ladekabel';
    case T.PREHEATING_BEFORE_FAST_CHARGING:
      return 'Forvarming før hurtiglading';
    case T.PLUG_AND_CHARGE:
      return 'Automatisk hurtiglading';
    default:
      return term;
  }
};

export default carTermsMapper;
