import React, { useCallback, useEffect, useState } from 'react';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { breakpoints, spacing, fontStyle, radius } from '@naf/theme';
import styled from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { AdvancedImage } from '@cloudinary/react';
import { useWindowResize } from '../../../hooks/Window/useWindowResize';
import { useDevicePixelRatio } from '../../../hooks/useDevicePixelRatio';
import { useCloudinary } from '../../../hooks/Cloudinary/useCloudinary';
import { PortraitsWithTextType, PortraitType } from '../../../../../types/portraitsWithTextType';
import BlockContent from '../../block-content/BlockContent';

type PortraitsWithTextProps = {
  block: PortraitsWithTextType;
};

export const Portrait = ({ item: { name, title, image, text } }: { item: PortraitType }) => {
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();

  // Starting at 500px and setting aspect ratio as portrait.
  const imgWidth = Math.round(500 / devicePixelRatio);
  const imgHeight = Math.round((500 * 1.25) / devicePixelRatio);
  const [cldImage, setCldImage] = useState(
    (image &&
      cld
        .image(image.public_id)
        .resize(fill().width(imgWidth).height(imgHeight).gravity(autoGravity()))
        .delivery(dpr(devicePixelRatio))
        .quality('auto:best')
        .format('auto')) ||
      undefined,
  );

  const advancedImage = cldImage ? (
    <AdvancedImage style={{ maxWidth: '100%' }} alt={image?.alt || image?.altText} cldImg={cldImage} />
  ) : undefined;

  const setResponsiveImage = useCallback(() => {
    if (typeof window !== 'undefined') {
      if (image && image.public_id) {
        if (window.innerWidth <= parseInt(breakpoints.m, 10)) {
          const width = Math.round(window.innerWidth / devicePixelRatio);
          const height = Math.round((window.innerWidth * 1.25) / devicePixelRatio);
          setCldImage(
            cld
              .image(image.public_id)
              .resize(fill().width(width).height(height).gravity(autoGravity()))
              .delivery(dpr(devicePixelRatio))
              .quality('auto:best')
              .format('auto'),
          );
        } else {
          // If screen is large, use appr 400 (container) as width
          const width = Math.round(400 / devicePixelRatio);
          const height = Math.round((400 * 1.25) / devicePixelRatio);
          setCldImage(
            cld
              .image(image.public_id)
              .resize(fill().width(width).height(height).gravity(autoGravity()))
              .delivery(dpr(devicePixelRatio))
              .quality('auto:best')
              .format('auto'),
          );
        }
      }
    }
  }, [cld, devicePixelRatio, image]);

  useEffect(() => {
    setResponsiveImage();
  }, [setResponsiveImage]);

  useWindowResize(() => {
    setResponsiveImage();
  });

  return (
    <PortraitWrapper>
      {advancedImage && <ImgWrap>{advancedImage}</ImgWrap>}
      {name && (
        <StyledHeader tag="h3" variant={TextVariant.SubHeader}>
          {name}
        </StyledHeader>
      )}
      {title && (
        <StyledTitle tag="p" variant={TextVariant.BodyText}>
          {title}
        </StyledTitle>
      )}
      {text && (
        <StyledText>
          <BlockContent value={text} />
        </StyledText>
      )}
    </PortraitWrapper>
  );
};

export const PortraitsWithTextBlock = ({ block }: PortraitsWithTextProps) => {
  if (block.portraits?.length > 0) {
    return (
      <StyledGrid className="PortraitsWithTextBlock">
        <StyledGridRow>
          {block.portraits.map((item) => (
            <GridCol s="12" m="6" l="4" xl="4" key={item._key || item.key}>
              <Portrait item={item} />
            </GridCol>
          ))}
        </StyledGridRow>
      </StyledGrid>
    );
  }
  return null;
};

const StyledGrid = styled(Grid)`
  margin: ${spacing.space48} 0;
`;

const StyledGridRow = styled(GridRow)`
  grid-row-gap: ${spacing.space80} !important;
  @media (max-width: ${breakpoints.m}) {
    grid-row-gap: ${spacing.space56} !important;
  }
`;

const PortraitWrapper = styled.div`
  @media (max-width: ${breakpoints.m}) {
    margin-top: 0;
  }
`;

const StyledHeader = styled(Text)`
  margin-bottom: 0;
`;

const StyledTitle = styled(Text)`
  margin-top: 0;
`;

const ImgWrap = styled.figure`
  margin: 0;
  img {
    border-radius: ${radius.m};
  }
`;

const StyledText = styled.div`
  ${fontStyle.article.caption};
  p {
    margin: 0;
  }
  ul {
    li {
      ${fontStyle.article.caption};
      list-style: disc;
      padding-left: 0;
      margin-bottom: 0;
      ::before {
        width: 0;
        height: 0;
      }
    }
  }
  @media (max-width: ${breakpoints.m}) {
    margin-top: 0;
  }
`;
