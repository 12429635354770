import React, { useState } from 'react';
import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { spacing } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { TextSnippet } from '@styled-icons/material-outlined/TextSnippet';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { AdvancedImage } from '@cloudinary/react';
import { useCloudinary } from '../../../../../hooks/Cloudinary/useCloudinary';
import useSelector from '../../../../../redux/typedHooks';
import { cloudinaryAttachment } from '../../../../../utils/imageUrl';
import DownloadArrowIcon from '../../../../icons/DownloadArrow';
import { CloudinaryFileType } from '../../../../../../../types/blockTypes';
import { formatFileSize } from '../../../../../utils/formatFileSize';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import { createNorwegianDateString } from '../../../../../utils/createNorwegianDateString';

interface Doc {
  documentLink: CloudinaryFileType;
  title: string;
}

interface DocumentsBlockSerializerProps {
  value: {
    documents: Doc[];
  };
}

const Document = ({ doc }: { doc: Doc }) => {
  const application = useSelector((state) => state.application);
  const cld = useCloudinary();

  const documentId = doc.documentLink.public_id || doc.documentLink.publicId;
  const imgId = doc.documentLink?.public_id || doc.documentLink?.publicId;
  const attachmentUrl = documentId && cloudinaryAttachment(documentId, application, doc.title);
  const [previewImage] = useState(
    (imgId &&
      cld
        .image(imgId)
        .resize(fill().width(32).height(32).gravity(autoGravity()))
        .quality('auto:best')
        .format('auto')) ||
      undefined,
  );

  const advancedImage = previewImage ? (
    <AdvancedImage style={{ maxWidth: '100%' }} alt={`${doc.title}`} cldImg={previewImage} plugins={[]} />
  ) : undefined;

  const isDocumentImage =
    doc.documentLink.format &&
    (doc.documentLink.format === 'jpg' || doc.documentLink.format === 'png' || doc.documentLink.format === 'svg');
  const createdDate = doc.documentLink.created_at
    ? createNorwegianDateString(new Date(doc.documentLink.created_at))
    : null;

  if (!doc?.documentLink) return null;

  return (
    <ListItem key={doc.documentLink.version}>
      <DocumentItemWrapper
        href={attachmentUrl}
        download={attachmentUrl ? `${doc.title}-${doc.documentLink.version}` : undefined}
      >
        <DocumentText>
          {isDocumentImage ? advancedImage : <TextSnippet size={32} />}
          <div>
            <DocumentHeader variant={TextVariant.BodyTextSubHeader} tag="h5">
              {doc.title || doc.documentLink.public_id}
            </DocumentHeader>
            {doc.documentLink.bytes && (
              <InfoText variant={TextVariant.Small} tag="span">
                {createdDate} {createdDate && <DotIcon />} {formatFileSize(doc.documentLink.bytes)}{' '}
                {doc.documentLink.format && <DotIcon />} {doc.documentLink.format?.toUpperCase()}
              </InfoText>
            )}
          </div>
        </DocumentText>
        <DownloadArrowIcon />
      </DocumentItemWrapper>
    </ListItem>
  );
};

export const DocumentsBlock = ({ value: { documents } }: DocumentsBlockSerializerProps) => (
  <BlockWrapper>{documents?.length > 0 && documents.map((doc) => <Document doc={doc} />)}</BlockWrapper>
);

export const DocumentsBlockSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: DocumentsBlockSerializerProps) => (
      <ArticleBlockContentGridCol>
        <DocumentsBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: DocumentsBlockSerializerProps) => <DocumentsBlock value={value} />;
};

const BlockWrapper = styled.ul`
  margin-bottom: ${spacing.space24};
  padding: 0;
`;

const ListItem = styled.li`
  list-style-type: none;
`;

const InfoText = styled(Text)`
  display: flex;
  align-items: center;
`;

const DotIcon = styled.span`
  height: 4px;
  width: 4px;
  background-color: ${nafColor.neutral.neutral4};
  border-radius: 50%;
  display: inline-block;
  margin: 0 ${spacing.space8} 0 ${spacing.space8};
`;

const DocumentItemWrapper = styled.a`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 0 ${spacing.space16} 0;
  border-bottom: 1px solid ${nafColor.neutral.neutral2};
  margin-bottom: ${spacing.space16};
  cursor: pointer;
  text-decoration: none;
  align-items: start;
  svg {
    padding-top: ${spacing.space4};
    width: 18px;
    height: 18px;
  }
`;

const DocumentText = styled.div`
  display: flex;
  align-items: start;
  svg {
    padding-top: 0;
    margin-right: ${spacing.space20};
    width: 32px;
    height: 32px;
  }
  img {
    margin-right: ${spacing.space20};
  }
`;

const DocumentHeader = styled(Text)`
  margin: 0 !important;
`;
