export enum EspialEventTypes {
  PURCHASE = 'purchase',
  VEHICLE_ADDED = 'vehicle_added',
  VEHICLE_REMOVED = 'vehicle_removed',
  NAF_CENTER_REPORT_OPENED = 'naf_senter_report_opened',
  VEHICLE_CONTRACT_OPENED = 'vehicle_contract_opened',
  LOGIN = 'login',
  LOGOUT = 'logout',
  MENU_CLICK = 'menu_click',
  HOUSEHOLD_MEMBER_ADDED = 'household_member_added',
  MEMBERSHIP_UPGRADED = 'membership_upgraded',
}
