import { Action, ActionType, createAsyncAction, createReducer } from 'typesafe-actions';
import { ofType } from 'redux-observable';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { produce } from 'immer';
import { ajax } from 'rxjs/ajax';
import { Boom } from '@hapi/boom';
import type { GetProductsResponse, MyProfileForm, ProductType, ProfileForm } from '@dtp/membership-service-types';
import { TypedEpic } from '../types';

// Actions
export enum Actions {
  GET_BECOME_A_MEMBER = 'naf/becomeAMember/GET_BECOME_A_MEMBER',
  GET_BECOME_A_MEMBER_SUCCESS = 'naf/becomeAMember/GET_BECOME_A_MEMBER_SUCCESS',
  GET_BECOME_A_MEMBER_FAIL = 'naf/becomeAMember/GET_BECOME_A_MEMBER_FAIL',
  GET_BECOME_A_MEMBER_CANCEL = 'naf/becomeAMember/GET_BECOME_A_MEMBER_CANCEL',
}

export interface State {
  data: {
    products: Record<ProductType['productNumber'], ProductType>;
    myProfileForm?: MyProfileForm;
    householdMemberForm?: ProfileForm;
    campaignCodeDetails?: GetProductsResponse['customerProducts']['campaignCodeDetails'] & {
      hasAvailableCampaignCodes?: boolean;
    };
  };
  isUpdating: boolean;
  errorState?: any;
}

export const initialState: State = {
  data: {
    products: {},
    myProfileForm: undefined,
    householdMemberForm: undefined,
    campaignCodeDetails: undefined,
  },
  isUpdating: false,
};

export const actions = {
  getBecomeAMember: createAsyncAction(
    Actions.GET_BECOME_A_MEMBER, // request payload creator
    Actions.GET_BECOME_A_MEMBER_SUCCESS, // success payload creator
    Actions.GET_BECOME_A_MEMBER_FAIL, // failure payload creator
    Actions.GET_BECOME_A_MEMBER_CANCEL, // optional cancel payload creator
  )<string | undefined, GetProductsResponse, Error, undefined>(),
};

export const reducers = createReducer<State, Action>(initialState, {})
  .handleAction(actions.getBecomeAMember.request, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = true;
    }),
  )
  .handleAction(actions.getBecomeAMember.success, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.data.myProfileForm = action.payload.profileForm || undefined;
      draftState.data.householdMemberForm = action.payload.householdMemberForm || undefined;
      draftState.data.products = action.payload.customerProducts.eligibleProducts.reduce(
        (acc: Record<ProductType['productNumber'], ProductType>, cur) => {
          acc[cur.productNumber] = cur;
          return acc;
        },
        {},
      );
      draftState.data.campaignCodeDetails = action.payload.customerProducts.campaignCodeDetails;
    }),
  )
  .handleAction(actions.getBecomeAMember.failure, (state = initialState, action) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
      draftState.errorState = action.payload;
    }),
  )
  .handleAction(actions.getBecomeAMember.cancel, (state = initialState) =>
    produce(state, (draftState) => {
      draftState.isUpdating = false;
    }),
  );

export const getBecomeAMemberEpic: TypedEpic = (action$: Observable<Action<any>>, state$) => {
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = state$.value.application;
  return action$.pipe(
    ofType(Actions.GET_BECOME_A_MEMBER),
    withLatestFrom(state$) as unknown as OperatorFunction<
      Action<any>,
      ActionType<typeof actions.getBecomeAMember.request>[]
    >,
    switchMap(([action]) => {
      let url = `${apimBaseUrl}/${apimMembershipApi}/v2/products`;
      if (action.payload) {
        url = `${apimBaseUrl}/${apimMembershipApi}/v2/products?campaignCode=${action.payload}`;
      }
      return ajax<GetProductsResponse>({
        url,
        headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
      }).pipe(
        map(({ response }) => actions.getBecomeAMember.success(response)),
        catchError(() =>
          of(
            actions.getBecomeAMember.failure(
              new Boom(
                'Oops, vi har problemer med motoren... Kunne ikke hente produktene! Ta kontakt med kundesenteret hvis problemet fortsetter!',
              ),
            ),
          ),
        ),
      );
    }),
  );
};

export const epics: TypedEpic[] = [getBecomeAMemberEpic];
