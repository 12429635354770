/**
 * Recursively omit keys from an object or array.
 * Returns a *new* object/array with those keys removed at any depth.
 *
 * @param data The source data (object or array).
 * @param keysToOmit The list of keys (string) you want to exclude at all levels.
 * @returns A new structure with omitted keys removed.
 */
export const omitNestedKeys = (data: object | any[], keysToOmit: string[]) => {
  if (Array.isArray(data)) {
    return data.map((item) => omitNestedKeys(item, keysToOmit));
  }

  if (data !== null && typeof data === 'object') {
    const newObj = Object.fromEntries(
      Object.entries(data)
        .filter(([key]) => !keysToOmit.includes(key) && data[key])
        .map(([key, value]) => [key, omitNestedKeys(value, keysToOmit)]),
    );
    return newObj;
  }

  return data;
};
