import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import useSelector from '../../../../../redux/typedHooks';
import { actions } from '../../../../../redux/modules/myMembership';
import { useAuth0Token } from '../../../../../hooks/useAuth0Token';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import { formatDateWithMonthName } from '../../../../../utils/Dates';

export const NumberOfUsedRoadAssistanceBlock = () => {
  const dispatch = useDispatch();
  const { simpleToken } = useAuth0Token();
  const roadSideAssistance = useSelector(
    (state) => state.myMembership.customerInformation.data.membership?.roadSideAssistance,
  );
  const fetchState = useSelector((state) => state.myMembership.customerInformation.meta.fetchState);

  useEffect(() => {
    if (simpleToken && fetchState === 'initial') {
      dispatch(actions.getCustomerInformation.request(simpleToken));
    }
  }, [simpleToken, dispatch, fetchState]);

  return (
    <>
      {roadSideAssistance && roadSideAssistance?.isActivationPeriod ? (
        <div>
          <BoldSpan>Din veihjelp er gyldig fra {formatDateWithMonthName(roadSideAssistance?.activationDate)}</BoldSpan>
          <p>
            Veihjelp uten egenandel har en karenstid på to døgn fra du blir medlem. Du kan få hjelp, mot betaling, i
            karensdagene.
          </p>
          <br />
        </div>
      ) : null}
      {roadSideAssistance && !roadSideAssistance.isActivationPeriod ? (
        <span>
          Du har benyttet {roadSideAssistance.usedRoadsideAssistancesInPeriod} av{' '}
          {roadSideAssistance.totalFreeRoadsideAssistancesInPeriod} assistanser.
        </span>
      ) : null}
    </>
  );
};

export const NumberOfUsedRoadAssistanceSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return () => (
      <ArticleBlockContentGridCol>
        <NumberOfUsedRoadAssistanceBlock />
      </ArticleBlockContentGridCol>
    );
  }
  return () => <NumberOfUsedRoadAssistanceBlock />;
};

const BoldSpan = styled.span`
  font-weight: 600;
`;
