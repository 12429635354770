import { spacing } from '@naf/theme';
import styled from 'styled-components';

export const BenefitHighlights = styled.div`
  &:not(:last-child) {
    margin-bottom: ${spacing.space64};
  }
`;

export const HighlightList = styled.div<{ $modularButtons?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.space24};
  margin-bottom: ${({ $modularButtons }) => ($modularButtons ? spacing.space64 : spacing.space12)};
  justify-content: center;

  & > * {
    flex: 1 1 calc(25% - ${spacing.space24});
  }

  @media (max-width: 1000px) {
    & > * {
      flex: 1 1 calc(50% - ${spacing.space24});
    }
  }

  @media (max-width: 480px) {
    & > * {
      flex: 1 1 100%;
    }
  }
`;
