import React from 'react';

import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import StepByStepList, { StepByStepListProps } from '../../../../StepByStepList/StepByStepList';

interface StepByStepListSerializerProps {
  value: StepByStepListProps;
}

export const StepByStepListBlock = ({ value }: StepByStepListSerializerProps) => (
  <StepByStepList title={value.title} ingress={value.ingress} list={value.list} variant={value.variant} />
);

export const StepByStepListSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ value }: StepByStepListSerializerProps) => (
      <ArticleBlockContentGridCol className="blockStepByStepList">
        <StepByStepListBlock value={value} />
      </ArticleBlockContentGridCol>
    );
  }
  return ({ value }: StepByStepListSerializerProps) => <StepByStepListBlock value={value} />;
};
